<template>
    <div class="typeahead-address.address-lookup">
        <label for="typeahead" :class="['typeahead-address.address-lookup.label', alignment]">Find address (please include space in postcodes)</label>
        <vue3-simple-typeahead
            ref="inputRef"
            class="typeahead"
            placeholder="Enter the postcode or address"
            v-model="searchAddress"
            :items="searchResults"
            :minInputLength="3"
            :itemProjection="itemProjection"
            @onFocus="onFocus"
            @keyup="getAddresses"
            @selectItem="onSelectAddress">
        </vue3-simple-typeahead>
    </div>
</template>

<script>
export default {
    name: "TypeaheadAddress",

    data() {
        return {
            searchAddress: "",
            searchResults: [],
        }
    },

    props: {
        region: String,
        alignment: String
    },

    emits: [
        'changed'
    ],

    methods: {
        async getAddresses() {
            const url = new URL(process.env.VUE_APP_API_PAFSEARCH)
            url.searchParams.append("q", this.searchAddress)
            url.searchParams.append("region", this.region)
            url.searchParams.append("format", "INTERNATIONAL")

            const apiResponse = await fetch(url.href, { headers: { "Authorization": process.env.VUE_APP_AUTHORIZATION } })
            const responseJson = await apiResponse.json()
            this.searchResults = responseJson.addresses
        },

        itemProjection(item) {
            return [
                item.address.ORG,
                item.address.ADDRESSLINE1,
                item.address.ADDRESSLINE2,
                item.address.ADDRESSLINE3,
                item.address.CITY,
                item.address.COUNTY,
                item.address.POSTCODE].filter(l => l).join(", ")
        },

        onSelectAddress(selectedValue) {
            this.$emit("changed", selectedValue)
        },

        onFocus() {
            this.$refs.inputRef.getInput().select()
        }
    }
}
</script>