<template>
    <button class="secondary-button">
      <span :class="['secondary-button.text', icon]">{{ btntxt }}</span>
    </button>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: {
    btntxt: String,
    icon: String
  }
}
</script>