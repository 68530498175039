<template>
    <div class="account-details" v-if="this.mainstore.user.user.confirmed === false">
        <ToastNotification ref="toast"/>
        <span class="account-details.close" @click="handleClose"></span>
        <h2 class="account-details.title">My account</h2>
        <p class="account-details.welcome">Hi {{ mainstore.user.user.firstName }}, from here you can manage your account details to make Online Postage even easier to use.</p>
        <div class="account-details.username">
            <span class="form-label">Username</span>
            <span class="account-details.username.data">{{ this.mainstore.user.user.username }}</span>
            <span class="account-details.username.text">Your username cannot be changed</span>
        </div>
        <div class="account-details.unconfirmed">
            <h3 class="account-details.unconfirmed.title">You have not yet verified your email address</h3>
            <p class="account-details.unconfirmed.text">Please open your email and click the link provided to verify your account and gain access to the account features</p>
            <MainButton btntxt="Resend verification email" @click="resendVerificationEmail" />
        </div>
        <span class="account-details.signout" @click="signOut">Sign out</span>
        <span class="account-details.delete" @click="deleteAccount">Delete account</span>
    </div>
    <div class="account-details" v-else>
        <ToastNotification ref="toast"/>
        <span class="account-details.close" @click="handleClose"></span>
        <h2 class="account-details.title">My account</h2>
        <p class="account-details.welcome">Hi {{ mainstore.user.user.firstName }}, from here you can manage your account details to make Online Postage even easier to use.</p>
        <div class="account-details.username">
            <span class="form-label">Username</span>
            <span class="account-details.username.data">{{ this.mainstore.user.user.username }}</span>
            <span class="account-details.username.text">Your username cannot be changed</span>
        </div>
        <!--
        <p class="account-details.data" v-for="item in mainstore.user.user" :key="item">{{ item }}</p>
        -->
        <!--
        <div class="account-details.block">
            <span class="account-details.block.title">Your name</span>
            <span class="account-details.data">
                {{ mainstore.user.user.firstName + ' ' + mainstore.user.user.lastName }}
            </span>
            <span class="account-details.block.edit" @click="editName">Change name</span>
        </div>
        -->
        <div class="account-details.block">
            <span class="account-details.block.title">Details</span>
            <label for="firstName" class="form-label">First name</label>
            <input id="firstName" type="text" class="form-text-field" name="firstName" v-model="mainstore.user.user.firstName" @change="editName"/>
            <label for="lastName" class="form-label">Last name</label>
            <input id="lastName" type="text" class="form-text-field" name="lastName" v-model="mainstore.user.user.lastName" @change="editName"/>
            <!--<MainButton v-if="editingName" btntxt="Save name" @click="saveDetails"/>-->
       
        <!--
        <div class="account-details.block">
            <span class="account-details.block.title">Your email</span>
            <span class="account-details.data">
                {{ mainstore.user.user.email }}
            </span>
            <span class="account-details.block.edit" @click="editEmail">Change email</span>
        </div>
        -->
        
            <!--<span class="account-details.block.title">Email</span>-->
            <label for="email" class="form-label">Email address</label>
            <input id="email" type="email" class="form-text-field" name="email" v-model="mainstore.user.user.email" @change="editEmail"/>
            <!--<MainButton v-if="editingEmail" btntxt="Save email address" @click="saveDetails"/>-->
       
        <!--
        <div class="account-details.block" v-if="!editingTelephone">
            <span class="account-details.block.title">Your telephone number</span>
            <span class="account-details.data">
                {{ mainstore.user.user.contactNumber }}
            </span>
            <span class="account-details.block.edit" @click="editTelephone">Change number</span>
        </div>
        -->
        
            <!--<span class="account-details.block.title">Telephone number</span>-->
            <label for="telephone" class="form-label">Telephone</label>
            <input id="telephone" type="text" class="form-text-field" name="telephone" v-model="mainstore.user.user.contactNumber" @change="editTelephone"/>
            <!--<MainButton v-if="editingTelephone" btntxt="Save telephone number" @click="saveDetails"/>-->
        </div>
        <div class="account-details.block">
            <span class="account-details.block.title">Address</span>
            <div v-if="mainstore.user.user.address">
                <span class="account-details.data">
                    <p v-if="mainstore.user.user.address?.ORG">
                        {{ capitalizeWords(mainstore.user.user.address.ORG) }}
                    </p>
                    <p v-if="mainstore.user.user.address?.ADDRESSLINE1">
                        {{ capitalizeWords(mainstore.user.user.address.ADDRESSLINE1) }}
                    </p>
                    <p v-if="mainstore.user.user.address?.ADDRESSLINE2">
                        {{ capitalizeWords(mainstore.user.user.address.ADDRESSLINE2) }}
                    </p>
                    <p v-if="mainstore.user.user.address?.ADDRESSLINE3">
                        {{ capitalizeWords(mainstore.user.user.address.ADDRESSLINE3) }}
                    </p>
                    <p v-if="mainstore.user.user.address?.CITY">
                        {{ capitalizeWords(mainstore.user.user.address.CITY) }}
                    </p>
                    <p v-if="mainstore.user.user.address?.POSTCODE">
                        {{ mainstore.user.user.address.POSTCODE }}
                    </p>
                </span>
                <span class="account-details.block.edit" @click="deleteAddress" v-if="basketstore.basketCount == 0">Change address</span>
                <p class="warning-text --light" v-if="basketstore.basketCount > 0">You cannot change your address while you have items in your basket</p>
            </div>
            <TypeaheadAddress v-else :valid="addressValid" @changed="addAddress" region="IMN" label="Select your address"/>
        </div>
        <div class="account-details.block">
            <span class="account-details.block.title">Contacts</span>
            <p class="account-details.block.text">Manage contacts you send items to regularly</p>
            <SecondaryButton btntxt="Manage contacts" class="--small-width" @click="openContactManager"/>
        </div>

        <div class="account-details.block">
            <span class="account-details.block.title">Marketing preferences</span>
            <p>If you wish to receive information on our services or offers, please advise how you prefer us to contact you.</p>
            <ul>
                <li>
                    <label class="checkbox --account">
                        Email
                        <input type="checkbox" v-model="marketingPreferences.email" @click="editMarketingPreferences"/>
                        <span class="checkbox.checkmark --account"></span>
                    </label>
                </li>
                <li>
                    <label class="checkbox --account">
                        SMS
                        <input type="checkbox" v-model="marketingPreferences.sms" @click="editMarketingPreferences"/>
                        <span class="checkbox.checkmark --account"></span>
                    </label>
                </li>
                <li>
                    <label class="checkbox --account">
                        Post
                        <input type="checkbox" v-model="marketingPreferences.post" @click="editMarketingPreferences"/>
                        <span class="checkbox.checkmark --account"></span>
                    </label>
                </li>
            </ul>
        </div>
   
        <MainButton class="--form-nav" btntxt="Save changes" :isLoading="isLoading" @click="saveUser" :type="--account" :class="{'--disabled': !fieldChanged}"/>
        <span class="account-details.signout" @click="signOut">Sign out</span>
        <span class="account-details.delete" @click="deleteAccount">Delete account</span>
    </div>
</template>

<script>
import TypeaheadAddress from './TypeaheadAddress.vue';
import MainButton from './MainButton.vue';
import SecondaryButton from './SecondaryButton.vue';
import { useMainStore } from '@/stores/MainStore.ts';
import { useBasketStore } from '@/stores/BasketStore.ts';
import axios from 'axios';
import anime from 'animejs';
import ToastNotification from './ToastNotification.vue';

export default {
    name: 'AccountDetails',

    components: {
        TypeaheadAddress,
        MainButton, 
        SecondaryButton,
        ToastNotification
    },

    emits: ['open'],

    setup() {
        const mainstore = useMainStore();
        const basketstore = useBasketStore();

        return {
            mainstore,
            basketstore
        }
    },

    data() {
        return {
            fieldChanged: false,
            editingName: null,
            editingEmail: null,
            editingTelephone: null,
            marketingPreferences: {
                email: false,
                sms: false,
                post: false
            },
            isLoading: false
        }
    },

    methods: {
        openContactManager() {
            this.$emit('open');
        },

        handleClose() {
            this.$emit('accountClosed');
            this.mainstore.modalOpen = false;
        },

        signOut() {
            this.handleClose();
            this.mainstore.user = null;
        },

        deleteAccount() {
            this.$emit('deleteAccount');
        },

        deleteAddress(){
            this.mainstore.user.user.address = null;
            this.fieldChanged = true;
        },

        addAddress(selectedValue){
            this.mainstore.user.user.address = selectedValue.address;
            this.mainstore.user.user.udprn = selectedValue.id;
            this.saveUser;
            this.fieldChanged = true;
        },

        capitalizeWords(str) {
            let words = str.toLowerCase().split(" ");
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].slice(1);
            }
            return words.join(" ");
        },

        editName() {
            this.editingName = true;
            this.fieldChanged = true;
        },

        editEmail() {
            this.editingEmail = true;
            this.fieldChanged = true;
        },

        editTelephone() {
            this.editingTelephone = true;
            this.fieldChanged = true;
        },

        editMarketingPreferences() {
            this.fieldChanged = true;
        },

        saveDetails() {
            this.editingName = false;
            this.editingEmail = false;
            this.editingTelephone = false;
        },

        saveUser() {
            this.mainstore.user.user.marketingPreferences = this.marketingPreferences;
            const data = this.mainstore.user.user;
            console.log(data);
            const token = this.mainstore.user.jwt;

            axios.put(process.env.VUE_APP_API_UPDATE, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                console.log('Response:', response.data);
                this.mainstore.user.user = response.data;
                this.editEmail = false;
                this.editingName = false;
                this.editingTelephone = false;
                this.fieldChanged = false;
                this.$refs.toast.showToast("Details successfully saved!");
            })
        },

        animate(){
            const sidebar = document.querySelector('.account-details');
            const sidebarWidth = sidebar.offsetWidth;
            anime({
                targets: '.account-details',
                translateX: [sidebarWidth, 0],
                easing: 'easeOutQuad',
                duration: 200
            });
        },

        moveToastToBody() {
            const toast = this.$refs.toast?.$el;
            if (toast) {
                document.body.appendChild(toast);
            }
        }
    },

    mounted() {
        this.animate();
        this.moveToastToBody();
    },

    created() {
        this.marketingPreferences = this.mainstore.user.user.marketingPreferences || {
            email: false,
            sms: false,
            post: false
        };
  }
}

</script>