<template>
    <button class="main-button" @click="$emit('continue')">
        <LoaderIcon v-if="isLoading" colour="dark"/>
        <span class="main-button.text" v-else>{{ btntxt }}</span>
    </button>
</template>

<script>
import LoaderIcon from './form-components/LoaderIcon.vue';

export default {
  name: 'MainButton',
  
  props: {
    btntxt: String,
    isLoading: Boolean
  },

  emits: ["continue"],

  components: {
    LoaderIcon
  }
}
</script>