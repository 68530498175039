<template>
    <div class="home-container">
        <HeroBanner hero="Buy your post online today!"/>
        <fieldset class="get-started" id="content">
            <h2 class="get-started.title" v-if="basketstore.basketCount > 0">Add another item</h2>
            <h2 class="get-started.title" v-else>Get Started</h2>
            <label for="destinationCountry" class="form-label --centred">Where would you like to send your item?</label>
            <select 
                name="destinationCountry"
                id="destinationCountry"
                class="form-select-field"
                v-model="selectedDestinationAlpha2"
            >
                <optgroup label="Most popular">
                    <option
                        v-for="option in mostPopular"
                        :key="option.alpha3Code"
                        :value="option.webRiposteCode"
                        :selected="option.webRiposteCode === 'UK'"
                    >
                        {{ option.name }}
                    </option>
                </optgroup>
                <optgroup label="Other countries">
                    <option
                        v-for="option in countries"
                        :key="option.id"
                        :value="option.webRiposteCode"
                        class="form-select-field.option"
                    >
                        {{ option.name }}
                    </option>
                </optgroup>
            </select>
            <p class="error" v-if="mainstore.destination === null || mainstore.destination === ''">{{ validationText }}</p>
            <WeightCalculator ref="weight" @maxChanged="setMaxWeight"/>
            <span v-if="basketstore.basketCount >= 10">
                <p class="warning-text">You have reached the maximum of 10 items allowed in a single consignment</p>
                <router-link to="/basket" class="main-button">
                    <span class="main-button.text">Checkout now</span>
                </router-link>
            </span>
            <p class="warning-text" v-if="basketstore.basketCount < 10">Please ensure you enter accurate weights as incorrect postage may incur delays and/or charges for the recipient. For international items over 2kg please visit an Isle of Man Post Office counter.</p>
            <router-link 
                v-if="collectionstore.collectionData.collectionAddress.length !== 0"
                to="/my-order/parcel-size" 
                class="main-button" 
                :class="{ '--disabled': mainstore.selected.destination === '' || mainstore.selected.weight === 0 || mainstore.selected.weight === null || mainstore.selected.weight > maxWeight || basketstore.basketCount >= 10 }"
                @click="setCollection()"
            >
                <span class="main-button.text">Get Started</span>
            </router-link>

            <router-link
                v-else
                to="/my-order/provide-items" 
                class="main-button" 
                :class="{ '--disabled': mainstore.selected.destination === '' || mainstore.selected.weight === 0 || mainstore.selected.weight === null || mainstore.selected.weight > maxWeight || basketstore.basketCount >= 10 }"
            >
                <span class="main-button.text">Get Started</span>
            </router-link>
            <span class="get-started.reset"><span class="get-started.reset.link" @click="handleClick()">Reset all data</span></span>
        </fieldset>
    </div>

    <div v-if="showModal" class="confirmation-modal">
        <div class="confirmation-modal.panel">
            <span class="confirmation-modal.panel.close" @click="closeConfirmationModal"></span>
            <div class="confirmation-modal.panel.content">
                <span class="confirmation-modal.panel.content.intro">Warning!</span>
                <span class="confirmation-modal.panel.content.title">This action will delete all data you have entered including any items in your basket</span>
                <span class="confirmation-modal.panel.content.text">Do you wish to continue?</span>
                <div class="confirmation-modal.panel.content.buttons">
                    <TertiaryButton btntxt="Yes please" @click="continueToRoute()"/>
                    <MainButton btntxt="No thanks" @continue="closeConfirmationModal()"/>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import axios from "axios";
    import { useMainStore } from '@/stores/MainStore.ts';
    import { useBasketStore } from '@/stores/BasketStore.ts';
    import { useCollectionStore } from '@/stores/CollectionStore.ts';
    import HeroBanner from '@/components/HeroBanner.vue';
    import WeightCalculator from '@/components/WeightCalculator.vue';
    import MainButton from "./MainButton.vue";
    import TertiaryButton from "./TertiaryButton.vue";

    export default {
        setup() {
            const mainstore = useMainStore();
            const basketstore = useBasketStore();
            const collectionstore = useCollectionStore();
            return {
                mainstore,
                basketstore,
                collectionstore
            }
        },

        name: 'GetStarted',

        emits: ['run-services-api'],

        components: {
            HeroBanner,
            WeightCalculator,
            MainButton,
            TertiaryButton
        },

        data() {
            return {
                countries: [],
                selectedDestinationAlpha2: 'UK',
                validationText: 'You must select a destination country',
                maxWeight: 20000,

                mostPopular: [
                    { alpha2Code: 'IM', alpha3Code: 'IMN', name: 'Isle of Man', numericCode: '833', parcelForceCode: 'GB', region: 'Europe', webRiposteCode: 'IM' },
                    { alpha2Code: 'GB', alpha3Code: 'GBR', name: 'United Kingdom', numericCode: '826', parcelForceCode: 'GB', region: 'Europe', webRiposteCode: 'UK' },
                    { alpha2Code: 'GB', alpha3Code: 'GBR', name: 'Northern Ireland', numericCode: '826', parcelForceCode: 'GB', region: 'Europe', webRiposteCode: 'UK' },
                    { alpha2Code: 'IE', alpha3Code: 'IRL', name: 'Ireland', numericCode: '372', parcelForceCode: 'IE', region: 'Europe', webRiposteCode: 'EI' },
                    { alpha2Code: 'AU', alpha3Code: 'AUS', name: 'Australia', numericCode: '036', parcelForceCode: 'AU', region: 'Oceania', webRiposteCode: 'AS' },
                    { alpha2Code: 'CA', alpha3Code: 'CAN', name: 'Canada', numericCode: '124', parcelForceCode: 'CA', region: 'Americas', webRiposteCode: 'CA' },
                    { alpha2Code: 'FR', alpha3Code: 'FRA', name: 'France', numericCode: '250', parcelForceCode: 'FR', region: 'Europe', webRiposteCode: 'FR' },
                    { alpha2Code: 'DE', alpha3Code: 'DEU', name: 'Germany', numericCode: '276', parcelForceCode: 'DE', region: 'Europe', webRiposteCode: 'GM' },
                    { alpha2Code: 'ES', alpha3Code: 'ESP', name: 'Spain', numericCode: '724', parcelForceCode: 'ES', region: 'Europe', webRiposteCode: 'SP' },
                    { alpha2Code: 'US', alpha3Code: 'USA', name: 'United States of America', numericCode: '840', parcelForceCode: 'US', region: 'Americas', webRiposteCode: 'US' }
                ],

                showModal: false
            }
        },

        watch: {
            selectedDestinationAlpha2(newVal) {
                this.updateSelectedDestination(newVal);
                this.updateParcelDetails(this.selectedDestinationAlpha2);
            }
        },

        mounted() {
            axios.get(process.env.VUE_APP_API_COUNTRIES, {
                headers: {
                    'Authorization': process.env.VUE_APP_AUTHORIZATION,
                }
            })
            .then(response => {
                this.countries = response.data.countryList.sort((a, b) => a.name.localeCompare(b.name));
            })
            .catch(err => console.error(err));

            this.mainstore.retrieveCollectionFee();
            this.updateSelectedDestination(this.selectedDestinationAlpha2);
            this.updateParcelDetails(this.selectedDestinationAlpha2);
        },

        methods: {
            updateSelectedDestination(alpha2) {
                const allCountries = [...this.mostPopular, ...this.countries];
                const selectedCountry = allCountries.find(
                    country => country.webRiposteCode === alpha2
                );

                this.mainstore.selected.destination = selectedCountry;
            },

            // Replace in future when API available
            updateParcelDetails(alpha2) {
                const weightMap = {
                    'IM': { weight: 30000, image: 'parcel.png', size: '61cm x 46cm x 46cm' },
                    'UK': { weight: 20000, image: 'parcel.png', size: '61cm x 46cm x 46cm' },
                    'GK': { weight: 20000, image: 'parcel.png', size: '61cm x 46cm x 46cm' },
                    'JE': { weight: 20000, image: 'parcel.png', size: '61cm x 46cm x 46cm' }
                };

                const defaultValues = { weight: 2000, image: 'intparcel.png', size: 'Length + Width + Depth combined must be less than 90cm. The longest side must be no longer than 60cm' };

                const parcelSettings = weightMap[alpha2] ?? defaultValues;

                this.maxWeight = parcelSettings.weight;

                const smallParcel = this.mainstore.products.find(p => p.id === 'smallparcel');
                if (smallParcel) {
                    smallParcel.maxWeight = parcelSettings.weight;
                    smallParcel.image = parcelSettings.image;
                    smallParcel.size = parcelSettings.size;
                }
            },

            runServicesAPI() {
                this.$emit('run-services-api');
            },

            setCollection() {
                this.mainstore.selected.methodOfSupply = 'collection';
            },

            resetAll() {
                window.resetLocalStorage();
                this.mainstore.fullReset();
                this.collectionstore.resetCollection();
                this.basketstore.resetBasket();
            },

            resetMainstore() {
                this.mainstore.reset();
            },

            handleClick() {
                this.showModal = true;
            },

            closeConfirmationModal() {
                this.showModal = false;
            },

            continueToRoute() {
                this.resetAll();
                this.$router.push('/');
                this.showModal = false;
            }
        },
    }
</script>
