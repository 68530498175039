<template>
    <div class="delete-modal">
      <div class="delete-modal.panel">
        <div class="delete-modal.panel.content">
          <h3 class="delete-modal.panel.content.title">
            Are you sure you want to permanently delete your account?
          </h3>
          <p class="delete-modal.panel.content.text">
            This action cannot be undone
          </p>
          <router-link to="/" class="main-button" style="margin-bottom: 10px" @click="deleteAccount"><span class="main-button.text" @click="handleContinue">Yes</span></router-link>
          <router-link to="/" class="main-button --back" @click="handleClose"><span class="main-button.text">No</span></router-link>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
        name: 'DeleteModal',

        methods: {
          handleClose() {
            this.$emit('confirmationClosed');
          },

          deleteAccount() {
            this.$emit('confirmedDeleteAccount')
          }
        }
    }

</script>