<template>
    <div class="senders-address">
        <h2 class="senders-address.title">Senders Address</h2>
        <div class="senders-address.address">
            <span v-if="collectionstore.collectionData.collectionAddress.ORG" class="senders-address.address.line">
                {{ capitalizeWords(collectionstore.collectionData.collectionAddress.ORG) }}
            </span>
            <span v-if="collectionstore.collectionData.collectionAddress.ADDRESSLINE1" class="senders-address.address.line">
                {{ capitalizeWords(collectionstore.collectionData.collectionAddress.ADDRESSLINE1) }}
            </span>
            <span v-if="collectionstore.collectionData.collectionAddress.ADDRESSLINE2" class="senders-address.address.line">
                {{ capitalizeWords(collectionstore.collectionData.collectionAddress.ADDRESSLINE2) }}
            </span>
            <span v-if="collectionstore.collectionData.collectionAddress.ADDRESSLINE3" class="senders-address.address.line">
                {{ capitalizeWords(collectionstore.collectionData.collectionAddress.ADDRESSLINE3) }}
            </span>
            <span v-if="collectionstore.collectionData.collectionAddress.CITY" class="senders-address.address.line">
                {{ capitalizeWords(collectionstore.collectionData.collectionAddress.CITY) }}
            </span>
            <span v-if="collectionstore.collectionData.collectionAddress.COUNTRY" class="senders-address.address.line">
                {{ capitalizeWords(collectionstore.collectionData.collectionAddress.COUNTRY) }}
            </span>
            <span v-if="collectionstore.collectionData.collectionAddress.POSTCODE">
                {{ collectionstore.collectionData.collectionAddress.POSTCODE }}
            </span>
        </div>
        <div class="warning-text --light" v-if="collectionstore.collectionData.methodOfSupply === 'COLLECTION' && this.$route.name !== 'provideItems'">
            This address is pre-selected as the collection address must be the same as your residential address. You can change this but you will need to start again.
        </div>
        <div class="warning-text --light" v-else-if="basketstore.basketCount > 0">
            This address has previously been entered. You may only enter one senders address. You can change this but you will need to start again.
        </div>
        <!--<span class="senders-address.edit-link" @click="editAddress">Add new senders address</span>-->
    </div>
</template>

<script>
import { useBasketStore } from '@/stores/BasketStore.ts';
import { useMainStore } from '@/stores/MainStore.ts';
import { useCollectionStore } from '@/stores/CollectionStore.ts';

export default {
    name: 'SendersAddress',

    emits: ['edit'],

    setup() {
        const basketstore = useBasketStore();
        const mainstore = useMainStore();
        const collectionstore = useCollectionStore();
        return {
            basketstore,
            mainstore,
            collectionstore
        }
    },

    /*
    computed: {
        uniqueAddresses() {
            const addresses = this.basketstore.basketItems.map(
                (item) => item.sendersDetails.address.address
            );


            const flattenedAddresses = [].concat(...addresses);

            const uniqueAddresses = [...new Set(flattenedAddresses)];

            return uniqueAddresses;
        }
    },
    */

    methods: {
        capitalizeWords(str) {
            let words = str.toLowerCase().split(" ");
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].slice(1);
            }
            return words.join(" ");
        },

        editAddress() {
            this.$emit('edit');
        }
    }
}
</script>
