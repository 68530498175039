<template>
    <div class="container">
      <fieldset class="recipient-details" v-if="mainstore.selected.usedContact == false">
        <h2 class="recipient-details.title">Destination address</h2>
        <SecondaryButton btntxt="Use my contacts" v-if="mainstore.user" class="--small-width --contacts" @click="openContacts"/>
        <TypeaheadAddress 
          v-if="['IMN', 'GBR', 'GGY', 'JEY'].includes(mainstore.selected.destination.alpha3Code)" 
          :valid="addressValid" 
          @changed="updateAddress" 
          :region="this.mainstore.selected.destination.alpha3Code" />
        <AddressForm 
          @valid="validateAddress"
          @invalid="invalidateAddress"
          @changed="validate" 
          v-if="addressPayload.length !== 0"
          :payload="addressPayload"
          :addressValid="addressValid"
        />
        <LoaderIcon v-else></LoaderIcon>
        <label for="recipientCountry" class="form-label">Country</label>
        <div class="form-field-icon --disabled">
          <input disabled type="text" class="form-text-field --disabled" name="recipientCountry" v-model="mainstore.selected.destination.name"/>
        </div>
      </fieldset>
      <fieldset class="recipient-details" v-if="mainstore.selected.usedContact == false">
        <h2 class="recipient-details.title">Recipient details</h2>
        <div class="recipient-details.business-container">
          <h3 class="recipient-details.sub-title">Are you sending to a business?</h3>
          <ToggleSwitch
            :value="isBusiness"
            selectedMessage="I am sending to a business"
            unselectedMessage="I am not sending to a business"
            @toggle="setIsBuisness"></ToggleSwitch>
        </div>
        <br/>
        <div v-if="isBusiness">
          <label for="recipientFirstName" class="form-label">Business name<span class="form-label.required">*</span></label>
          <div class="form-text-field.container" :class="{ '--valid': businessNameValid === true}">
            <input 
              type="text" 
              class="form-text-field" 
              name="recipientFirstName" 
              v-model.trim="mainstore.selected.recipientDetails.address.RECIPIENTORG" 
              @blur="validateBusinessName(true)"
            />
            <p class="error" v-if="businessNameTouched && businessNameValid === false">You must enter a business name</p>
          </div>
        </div>
        <label for="recipientFirstName" class="form-label">First name<span class="form-label.required" v-if="!isBusiness">*</span></label>
        <div class="form-text-field.container" :class="{ '--valid': firstNameValid === true}">
          <input 
            type="text" 
            class="form-text-field" 
            name="recipientFirstName" 
            v-model.trim="mainstore.selected.recipientDetails.firstName" 
            @blur="validateFirstName(true)"
          />
        </div>
        <p class="error" v-if="firstNameTouched && firstNameValid === false">You must enter a first name greater than two characters in length</p>
        <label for="recipientLastName" class="form-label">Last name<span class="form-label.required" v-if="!isBusiness">*</span></label>
        <div class="form-text-field.container" :class="{ '--valid': lastNameValid === true}">
          <input 
            type="text" 
            class="form-text-field" 
            name="recipientLastName" 
            v-model.trim="mainstore.selected.recipientDetails.lastName" 
            @blur="validateLastName(true)"
          />
        </div>
        <p class="error" v-if="lastNameTouched && lastNameValid === false">You must enter a last name greater than two characters in length</p>
        <p class="error" v-if="firstNameTouched && lastNameTouched && fullNameValid === false">Both the first and last name must be less than 40 characters combined</p>
        <div v-if="mainstore.selected.serviceDetails.controlCode !== 'IM'">
            <label for="recipientContactTelephone" class="form-label">Recipient contact telephone<span class="form-label.required">*</span></label>
            <div class="form-text-field.container" :class="{ '--valid': telephoneValid === true}">
            <input 
                type="tel" 
                class="form-text-field"
                name="recipientContactTelephone" 
                v-model.trim="mainstore.selected.recipientDetails.telephone" 
                @blur="validateTelephone"
            />
            </div>
            <p class="error" v-if="telephoneTouched && telephoneValid === false">You must enter a valid telephone number</p>
        </div>
        <div class="recipient-details.checkbox" v-if="showCheckbox && this.mainstore.validations.recipientDetailsValid">
          <!--
            <label class="checkbox --account">
            Store this recipent for future use
            <input type="checkbox" v-model="storeRecipient" />
            <span class="checkbox.checkmark --account"></span>
          </label>
          -->
          <SecondaryButton btntxt="Save recipient" v-if="mainstore.user !== null && contactSaved == false && addressBookCount < 100" @click="saveAddressToAddressBook" class="--small"/>
          <span class="saved-message" v-if="contactSaved">Address saved</span>
        </div>
      </fieldset>
      <div class="recipient-details" v-else>
        <h2 class="recipient-details.title">Destination address</h2>
        <SecondaryButton btntxt="Add new address" class="--small-width --contacts" @click="resetForm"/>
        <div class="recipient-details.contact">
          <span 
            class="recipient-details.contact.name" 
            v-if="(mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.firstName) || (mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.lastName)">
            {{ capitalizeWords(((mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.firstName) || '') + ' ' + ((mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.lastName) || '')).trim() }}
          </span>
          <div class="recipient-details.contact.address">
            <span 
              v-if="mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.address && mainstore.selected.recipientDetails.address.RECIPIENTORG" 
              class="recipient-details.contact.address.line">
              {{ capitalizeWords(mainstore.selected.recipientDetails.address.RECIPIENTORG) }}
            </span>
            <span 
              v-if="mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.address && mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1" 
              class="recipient-details.contact.address.line">
              {{ capitalizeWords(mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1) }}
            </span>
            <span 
              v-if="mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.address && mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE2" 
              class="recipient-details.contact.address.line">
              {{ capitalizeWords(mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE2) }}
            </span>
            <span 
              v-if="mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.address && mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE3" 
              class="recipient-details.contact.address.line">
              {{ capitalizeWords(mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE3) }}
            </span>
            <span 
              v-if="mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.address && mainstore.selected.recipientDetails.address.RECIPIENTCITY" 
              class="recipient-details.contact.address.line">
              {{ capitalizeWords(mainstore.selected.recipientDetails.address.RECIPIENTCITY) }}
            </span>
            <span 
              v-if="mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.address && mainstore.selected.recipientDetails.address.RECIPIENTCOUNTY" 
              class="recipient-details.contact.address.line">
              {{ capitalizeWords(mainstore.selected.recipientDetails.address.RECIPIENTCOUNTY) }}
            </span>
            <span 
              v-if="mainstore.selected.destination.name" 
              class="recipient-details.contact.address.line">
              {{ capitalizeWords(mainstore.selected.destination.name) }}
            </span>
            <span 
              v-if="mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.address && mainstore.selected.recipientDetails.address.RECIPIENTPOSTCODE" 
              class="recipient-details.contact.address.line">
              {{ mainstore.selected.recipientDetails.address.RECIPIENTPOSTCODE }}
            </span>
          </div>
          <span 
            class="recipient-details.contact.telephone" 
            v-if="mainstore.selected.recipientDetails && mainstore.selected.recipientDetails.telephone">
            {{ mainstore.selected.recipientDetails.telephone }}
          </span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { useMainStore } from '@/stores/MainStore.ts';
  import AddressForm from '@/components/form-components/AddressForm.vue';
  import LoaderIcon from '@/components/form-components/LoaderIcon.vue';
  import ToggleSwitch from './form-components/ToggleSwitch.vue';
  import TypeaheadAddress from '@/components/TypeaheadAddress.vue';
  import SecondaryButton from './SecondaryButton.vue';
  
  export default {
    name: 'RecipientDetails',
    components: {
      AddressForm,
      LoaderIcon,
      ToggleSwitch,
      TypeaheadAddress,
      SecondaryButton
    },
    setup() {
      const mainstore = useMainStore();
      return {
        mainstore
      };
    },
    data() {
      return {
        isBusiness: false,
        addressPayload: [],
        businessNameValid: null,
        businessNameTouched: false,
        firstNameValid: null,
        firstNameTouched: false,
        lastNameValid: null,
        lastNameTouched: false,
        fullNameValid: false,
        telephoneValid: null,
        telephoneTouched: false,
        addressValid: null,
        showCheckbox: true,
        storeRecipient: false,
        contactSaved: false
      };
    },

    computed: {
      addressBookCount() {
          return this.mainstore.getAddressBookCount();
      },
    },

    created() {
      this.fetchAddressPayload();
      if(this.mainstore.selected.serviceDetails.controlCode === 'IM') {
        this.telephoneValid = true;
      }
    },

    mounted() {
      if(this.mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1) {
        this.changeBusinessType();
        this.validateRecipientDetails();

        if(this.mainstore.selected.serviceDetails.controlCode !== 'IM') {
          this.validateTelephone();
        }
      }
      const toggleSwitch = this.$refs.ToggleSwitch;
      if (toggleSwitch) {
        toggleSwitch.setToggle(this.isBusiness);
      }

      if(this.mainstore.selected.usedContact === true) {
        this.mainstore.validations.recipientDetailsValid = true;
      }
    },

    methods: {
      resetForm() {
        this.mainstore.selected.usedContact = false;
        this.mainstore.selected.recipientDetails = 
        {
            firstName: '',
            lastName: '',
            telephone: '',
            address: {}
        };
        this.mainstore.validations.recipientDetailsValid = false;
      },

      openContacts() {
        this.mainstore.contactsOpen = true;
      },

      capitalizeWords(str) {
        let words = str.toLowerCase().split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i][0].toUpperCase() + words[i].slice(1);
        }
        return words.join(" ");
      },

      fetchAddressPayload() {
        axios({
          method: 'get',
          url: process.env.VUE_APP_API_CUSTOMSFORMAT,
          params: {
            product: this.mainstore.selected.serviceDetails.id,
            country: this.mainstore.selected.destination.alpha3Code,
            itemformat: this.mainstore.selected.parcelSize.itemFormat
          },
          headers: {
            'Authorization': process.env.VUE_APP_AUTHORIZATION,
          }
        })
        .then(response => {
          this.addressPayload = response.data.recipientLines;
          delete this.addressPayload.RECIPIENTORG;
        })
        .catch(err => {
          console.error('Error fetching address payload:', err);
        });
      },
      validateBusinessName(manualInput) {
        let data = this.mainstore.selected.recipientDetails.address.RECIPIENTORG;
        console.log(data);
        if (this.isBusiness && data) {
          this.mainstore.selected.recipientDetails.address.RECIPIENTORG = data;
          this.businessNameValid = data != '' && data.length >= 1;
        } else {
          this.businessNameValid = false;
        }
        if (data || manualInput) {
          this.businessNameTouched = true;
        }
        this.validate();
      },
      validateFirstName(manualInput) {
        let data = this.mainstore.selected.recipientDetails.firstName;
        if (this.isBusiness) {
          this.firstNameValid = data === '' || data.length >= 3 || data;
        } else {
          this.firstNameValid = data === '' || data.length >= 3 || data;
          this.firstNameValid = data !== '' && data.length >= 3;
        }
        if (data || manualInput) {
          this.firstNameTouched = true;
        }
        this.fullNameValid = data.length + this.mainstore.selected.recipientDetails.lastName.length < 40;
        this.validate();
      },
      validateLastName(manualInput) {
        let data = this.mainstore.selected.recipientDetails.lastName;
        if (this.isBusiness) {
          this.lastNameValid = data === '' || data.length >= 3  || data;
        } else {
          this.lastNameValid = data !== '' && data.length >= 2;
        }
        if (data || manualInput) {
          this.lastNameTouched = true;
        }
        this.fullNameValid = data.length + this.mainstore.selected.recipientDetails.firstName.length < 40;
        this.validate();
      },
      validateRecipientDetails() {
        if (this.isBusiness) {
          this.businessNameTouched = false;
          this.validateBusinessName();
          this.fullNameValid = true;
        } else {
          this.fullNameValid = false;
          this.firstNameTouched = false;
          this.validateFirstName(false);
          this.lastNameTouched = false;
          this.validateLastName(false);
        }
        this.validate();
      },
      validateTelephone() {
        let data = this.mainstore.selected.recipientDetails.telephone;
        // eslint-disable-next-line
        this.telephoneValid = /^[\(\)\+ 0-9]{10,20}$/.test(data);
        this.telephoneTouched = true;
        this.validate();
      },
      setIsBuisness() {
        this.isBusiness = !this.isBusiness;

        var firstName = this.mainstore.selected.recipientDetails.firstName;
        var lastName = this.mainstore.selected.recipientDetails.lastName;

        this.businessNameTouched = this.mainstore.selected.recipientDetails.address.RECIPIENTORG ? true : false;
        this.businessNameValid = this.mainstore.selected.recipientDetails.address.RECIPIENTORG ? true : false;
        // this.firstNameTouched = firstName || firstName == '' ? true : false;
        this.firstNameValid = firstName || firstName == '' ? true : false;
        // this.lastNameTouched = lastName || lastName == '' ? true : false;
        this.lastNameValid = lastName || lastName == '' ? true : false;

        this.validateRecipient();
        this.validateRecipientDetails();
      },
      changeBusinessType() {
        console.log(this.mainstore.selected.recipientDetails.address.RECIPIENTORG);
        if (this.mainstore.selected.recipientDetails.address.RECIPIENTORG) {
          this.isBusiness = true;
          this.businessNameTouched = true;
        } else {
          this.businessNameTouched = false;
          this.isBusiness = false;
        }

        this.validateRecipient();
        },
      validateRecipient() {
        if (this.businessNameTouched) {
          this.validateBusinessName(false);
        } 
        if (this.firstNameTouched) {
          this.validateFirstName(false);
        }
        if (this.lastNameTouched) {
          this.validateLastName(false);
        }
      },
      validateAddress() {
        this.validate();
        if (this.mainstore.selected.recipientDetails.address.RECIPIENTORG) {
          this.isBusiness = true;
        } else {
          this.isBusiness = false;
        }
        this.addressValid = true;
        this.validateRecipientDetails();
      },
      invalidateAddress() {
        this.addressValid = false;
        this.validate();
      },
      addressChanged() {
        this.changeBusinessType();
        this.validateRecipientDetails();
        // this.validate(); Handled in validatedRecipientDetails()
      },
      validate() {
        const firstName = this.mainstore.selected.recipientDetails.firstName;
        const lastName = this.mainstore.selected.recipientDetails.lastName;

        if (!this.isBusiness) {
          this.mainstore.validations.recipientDetailsValid =
            this.firstNameValid &&
            this.lastNameValid &&
            this.fullNameValid &&
            this.telephoneValid &&
            this.addressValid;
        } else if (firstName || lastName) {
          this.mainstore.validations.recipientDetailsValid =
            this.businessNameValid &&
            this.firstNameValid &&
            this.lastNameValid &&
            this.fullNameValid &&
            this.telephoneValid &&
            this.addressValid;
        } else {
          this.mainstore.validations.recipientDetailsValid =
            this.businessNameValid &&
            this.telephoneValid &&
            this.addressValid;
        }
      },
      updateAddress(address) {
        this.mainstore.selected.recipientDetails.address.RECIPIENTORG = undefined;
        this.addressValid = true;
        this.validate();
        //console.log(address.address.ORG);
        this.mainstore.$patch((state) => {
          state.selected.recipientDetails.address.RECIPIENTORG = address.address.ORG || ""
          state.selected.recipientDetails.address.RECIPIENTADDRESSLINE1 = address.address.ADDRESSLINE1 || ""
          state.selected.recipientDetails.address.RECIPIENTADDRESSLINE2 = address.address.ADDRESSLINE2 || ""
          state.selected.recipientDetails.address.RECIPIENTADDRESSLINE3 = address.address.ADDRESSLINE3 || ""
          state.selected.recipientDetails.address.RECIPIENTCITY = address.address.CITY
          state.selected.recipientDetails.address.RECIPIENTCOUNTY = address.address.COUNTY || ""
          state.selected.recipientDetails.address.RECIPIENTPOSTCODE = address.address.POSTCODE
          state.hasChanged = true
        })
        this.validateAddress();
        this.addressChanged();
      },

      saveAddressToAddressBook() {
        // Initialize addressBook as an empty array if it is null
        if (!this.mainstore.user.user.addressBook) {
          this.mainstore.user.user.addressBook = [];
        }

        const newAddress = {
          id: `contact_${Date.now()}`,
          ADDRESSLINE1: this.mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE1 || '',
          ADDRESSLINE2: this.mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE2 || '',
          ADDRESSLINE3: this.mainstore.selected.recipientDetails.address.RECIPIENTADDRESSLINE3 || '',
          CITY: this.mainstore.selected.recipientDetails.address.RECIPIENTCITY || '',
          COUNTY: this.mainstore.selected.recipientDetails.address.RECIPIENTCOUNTY || '',
          POSTCODE: this.mainstore.selected.recipientDetails.address.RECIPIENTPOSTCODE || '',
          countryName: this.mainstore.selected.destination.name || '',
          country: this.mainstore.selected.destination.webRiposteCode || '',
          FIRSTNAME: this.mainstore.selected.recipientDetails.firstName || '',
          LASTNAME: this.mainstore.selected.recipientDetails.lastName || '',
          TELEPHONE: this.mainstore.selected.recipientDetails.telephone || '',
          ORG: this.mainstore.selected.recipientDetails.address.RECIPIENTORG || ''
        };

        this.mainstore.user.user.addressBook.push(newAddress);
        this.saveContact();
        this.contactSaved = true;
      },


      saveContact() {
        const addressBookAsObject = this.mainstore.user.user.addressBook;

        const data = { 
          ...this.mainstore.user.user, 
          addressBook: addressBookAsObject 
        };

        const token = this.mainstore.user.jwt;

        axios.put(process.env.VUE_APP_API_UPDATE, data, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        .then(response => {
          this.mainstore.user.user = response.data;
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .saved-message {
    display: block;
    margin-top: 10px;
    font-weight: bold;
    color: #229609;
  }
  </style>