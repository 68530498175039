import { createRouter, createWebHistory } from 'vue-router';

// My components
import GetStarted from './components/GetStarted.vue';
import MainBasket from './components/MainBasket.vue';
import OrderForm from './components/OrderForm.vue';
import ParcelSize from './components/ParcelSize.vue';
import AvailableServices from './components/AvailableServices.vue';
import RecipientDetails from './components/RecipientDetails.vue';
import SendersDetails from './components/SendersDetails.vue';
import CustomsForm from './components/CustomsForm.vue';
import OrderSummary from './components/OrderSummary.vue';
import SafeToFly from './components/SafeToFly.vue';
import LabelsContent from './components/LabelsContent.vue';
import PostboxContent from './components/PostboxContent.vue';
import CollectionContent from './components/CollectionContent.vue';
import HowtoContent from './components/HowtoContent.vue';
import TermsConditions from './components/TermsConditions.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import CookiePolicy from './components/CookiePolicy.vue';
import FaqContent from './components/FaqContent.vue';
import WorldpayCheckout from './components/WorldpayCheckout.vue';
import NotFound from './components/NotFound.vue';
import OrderConfirmation from './components/OrderConfirmation.vue';
import ConfirmationError from './components/ConfirmationError.vue';
import ProvideItems from './components/ProvideItems.vue';
import PasswordReset from './components/PasswordReset.vue';
import EmailConfirmed from './components/EmailConfirmed.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { 
            path: '/', 
            name: 'home',
            component: GetStarted,
            meta: { title: 'Home', priority: 1.0 }
        },
        {
            path: '/labels',
            name: 'labels',
            component: LabelsContent,
            meta: { title: 'Labels', priority: 0.8 }
        },
        {
            path: '/parcel-postboxes',
            name: 'parcelpostboxes',
            component: PostboxContent,
            meta: { title: 'Parcel Postboxes', priority: 0.8 }
        },
        {
            path: '/collection',
            name: 'collection',
            component: CollectionContent,
            meta: { title: 'Collection', priority: 0.8 }
        },
        {
            path: '/how-to',
            name: 'howto',
            component: HowtoContent,
            meta: { title: 'How to', priority: 0.8 }
        },
        {
            path: '/faq',
            name: 'faq',
            component: FaqContent,
            meta: { title: 'Frequently Asked Questions', priority: 0.8 }
        },
        {
            path: '/termsandconditions',
            name: 'termsandconditions',
            component: TermsConditions,
            meta: { title: 'Terms and Conditions', priority: 0.4 }
        },
        {
            path: '/privacypolicy',
            name: 'privacypolicy',
            component: PrivacyPolicy,
            meta: { title: 'Privacy Policy', priority: 0.4 }
        },
        {
            path: '/cookiepolicy',
            name: 'cookiepolicy',
            component: CookiePolicy,
            meta: { title: 'Cookie Policy', priority: 0.4 }
        },
        { 
            path: '/my-order',
            name: 'myorder',
            component: OrderForm,
            children: [
                {
                    path: 'provide-items',
                    name: 'provideItems',
                    component: ProvideItems,
                    meta: { title: 'Provide Items', priority: 0 }
                },
                {
                    path: 'parcel-size',
                    name:   'parcelSize',
                    component: ParcelSize,
                    meta: { title: 'Parcel Size', priority: 0 }
                },
                {
                    path: 'services',
                    name: 'services',
                    component: AvailableServices,
                    meta: { title: 'Available Services', priority: 0 }
                },
                {
                    path: 'recipient-details',
                    name: 'recipient',
                    component: RecipientDetails,
                    meta: { title: 'Recipient Details', priority: 0 }
                },
                {
                    path: 'senders-details',
                    name: 'sender',
                    component: SendersDetails,
                    meta: { title: 'Senders Details', priority: 0 }
                },
                {
                    path: 'customs',
                    name: 'customs',
                    component: CustomsForm,
                    meta: { title: 'Customs', priority: 0 }
                },
                {
                    path: 'order-summary',
                    name: 'orderSummary',
                    component: OrderSummary,
                    meta: { title: 'Order Summary', priority: 0 }
                },
                {
                    path: 'safe-to-fly',
                    name: 'safeToFly',
                    component: SafeToFly,
                    meta: { title: 'Safe to Post', priority: 0 }
                }
            ]
        },
        {
            path: '/basket',
            name: 'basket',
            component: MainBasket,
            meta: { title: 'Basket', priority: 0 }
        },
        {
            path: '/checkout',
            name: 'checkout',
            component: WorldpayCheckout,
            meta: { title: 'Checkout', priority: 0 }
        },
        {
            path: '/confirmation/:consignmentRef?',
            name: 'confirmation',
            component: OrderConfirmation,
            meta: { title: 'Confirmation', priority: 0  }
        },
        {
            path: '/confirmation-error',
            name: 'confirmationError',
            component: ConfirmationError,
            meta: { title: 'Error', priority: 0  }
        },
        {
            path: '/password-reset',
            name: 'passwordReset',
            component: PasswordReset,
            meta: { title: 'Password reset' }
        },
        {
            path: '/account-created',
            name: 'accountCreated',
            component: EmailConfirmed,
            meta: { title: 'Email confirmed' }
        },
        {
            path: '/:catchAll(.*)',
            name: 'Error404',
            component: NotFound,
            meta: { title: 'Not Found', priority: 0  }
        }
    ],

    // Make page scroll to top on route change
    scrollBehavior: function (to, _from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        } else {
            window.scrollTo(0, 0);
        }
    },
});

export default router;