import { createApp } from 'vue'
import './scss/styles.scss';
import App from './App.vue';
import { createPinia } from 'pinia';
import axios from 'axios';
import VueAxios from 'vue-axios';
import SimpleTypeahead from 'vue3-simple-typeahead';
//import { createGtm } from '@gtm-support/vue-gtm';

import { useMainStore } from './stores/MainStore.ts';

import { useBasketStore } from './stores/BasketStore.ts'
import { useCollectionStore } from './stores/CollectionStore.ts';

import router from './router';

let lastRouteName = null;

router.beforeEach(async (to, from, next) => {
    const mainStore = useMainStore();
    const basketStore = useBasketStore();
    const collectionStore = useCollectionStore();

    if (basketStore.lastUpdated !== null && basketStore.isOlderThanMidnight()) {
        basketStore.resetBasket();
      }
    
      if (collectionStore.lastUpdated !== null && collectionStore.isOlderThanMidnight()) {
        collectionStore.resetCollection();
      }
    
    if (from.name === 'basket') {
        await mainStore.reset();
    }

    // Detect navigation from /checkout to /basket
    if (from.name === 'checkout' && to.name === 'basket') {
        mainStore.setNavigatedFromCheckout(true);
    } else {
        mainStore.setNavigatedFromCheckout(false);
    }

    lastRouteName = from.name;
    next();
});

router.afterEach((to) => {
    const defaultTitle = 'Online Postage';
    document.title = to.meta.title || defaultTitle;
});

router.afterEach((to) => {
    if (to.path !== '/')
        try {
            /* global fbq */
            fbq('track', 'ViewContent')
        } catch {
            console.log("Analytics disabled")
        }

    if (to.name === 'confirmation') {
        window.dataLayer.push({
            event: 'conversion',
            category: 'Transaction',
            action: 'Complete',
            label: 'Order Confirmation',
            value: 1,
        })
    } else {
        window.dataLayer.push({
            event: 'pageview',
            page_title: to.name || document.title,
            page_path: to.fullPath,
        })
    }
})

window.addEventListener('popstate', () => {
    if (lastRouteName === 'basket') {
        router.replace({ name: 'home' });
        lastRouteName = null;
    }
});

window.resetLocalStorage = () => {
    localStorage.removeItem("basketLastUpdated")
    localStorage.removeItem("consignment")
    localStorage.removeItem("Validations")
    localStorage.removeItem("sendersDetails")
    localStorage.removeItem("basket")
    localStorage.removeItem("CollectionData")
    localStorage.removeItem("Selected")
    localStorage.removeItem("collectionLastUpdated")
}

// Initialise 
const app = createApp(App)
const pinia = createPinia()
export default pinia

app.use(VueAxios, axios)
app.use(SimpleTypeahead)
app.use(router)
app.use(pinia) // needs to be last for dev tools

app.mount('#app');

//export { router };