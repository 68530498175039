<template>
    <div class="form-navigation">
        <router-link 
            :to="buttonLinkBack" 
            class="main-button --form-nav --back" 
        >
            <span class="main-button.text">Back</span>
        </router-link>

        <button 
            class="main-button --form-nav" 
            :class="{ '--disabled': pageIsValid == false || pageIsValid == null }" 
            @click="handleNextClick"
        >
            <LoaderIcon v-if="isLoading" colour="dark"/>
            <span class="main-button.text" v-else>{{ nextButtonText }}</span>
        </button>
    </div>
</template>

<script>
    import LoaderIcon from '@/components/form-components/LoaderIcon.vue';
    import { useMainStore } from '@/stores/MainStore.ts';
    import { useBasketStore } from '@/stores/BasketStore.ts';
    import { useCollectionStore } from '@/stores/CollectionStore.ts';
    import axios from "axios";

    export default {
        setup() {
            const mainStore = useMainStore();
            const basketStore = useBasketStore();
            const collectionStore = useCollectionStore();

            return { 
                mainStore,
                basketStore,
                collectionStore
            }
        },

        name: 'FormNavigation',

        components: {
            LoaderIcon,
        },

        data() {
            return {
                isLoading: false,
                apiResponse: [],
                consignmentResponse: [],
                consignmentError: false,
                errorMessage: null
            }
        },

        computed: {
            nextButtonText() {
                if (
                    this.$route.name === 'customs' || this.$route.name === 'sender' && this.mainStore.selected.destination.alpha3Code === 'IMN' || this.$route.name === 'sender' && this.mainStore.selected.destination.alpha3Code === 'GBR'
                ) {
                    return 'Add to basket';
                }

                return 'Continue';
            },

            buttonLinkNext() {
                if (this.consignmentError) {
                    return null;
                }
                switch (this.$route.name) {
                    case 'provideItems':
                        return '/my-order/parcel-size';
                    case 'parcelSize':
                        return '/my-order/recipient-details';
                    case 'recipient':
                        return '/my-order/senders-details';
                    case 'sender':
                        // Check if alpha code is 'IMN' or 'GBR'
                        if (['IMN', 'GBR'].includes(this.mainStore.selected.destination.alpha3Code)) {
                            return '/basket';
                        } else {
                            return '/my-order/customs';
                        }
                    case 'customs':
                        return '/basket';
                    //case 'orderSummary':
                    //    return '/my-order/safe-to-fly';
                    default:
                        return '/basket';
                }
            },

            buttonLinkBack() {
                const routeName = this.$route.name;

                switch (routeName) {
                    case 'provideItems':
                        return '/';
                    case 'parcelSize':
                        return '/my-order/provide-items';
                    case 'recipient':
                    return '/my-order/parcel-size';
                    case 'sender':
                        return '/my-order/recipient-details';
                    case 'customs':
                        // Check if alpha code is 'IMN'
                        if (this.mainStore.selected.destination.alpha3Code === 'IMN') {
                            return '/my-order/senders-details';
                        } else {
                            return '/my-order/senders-details';
                        }
                    case 'orderSummary':
                        // Check if alpha code is 'IMN' or 'GBR'
                        if (this.mainStore.selected.destination.alpha3Code === 'IMN' || this.mainStore.selected.destination.alpha3Code === 'GBR') {
                            return '/my-order/senders-details';
                        } else {
                            return '/my-order/customs';
                        }
                    default:
                        return '/my-order/order-summary';
                }
            },
            
            pageIsValid() {
              switch (this.$route.name) {
                case 'provideItems':
                    return this.mainStore.validations.provideItemsValid;
                case 'parcelSize':
                  return this.mainStore.validations.parcelSizeValid && this.mainStore.validations.serviceValid;
                case 'services':
                  return this.mainStore.validations.serviceValid;
                case 'recipient':
                  return this.mainStore.validations.recipientDetailsValid;
                case 'sender':
                  return this.mainStore.validations.sendersDetailsValid;
                case 'customs':
                  return this.mainStore.validations.customsValid;
                //case 'safeToFly':
                //  return this.mainStore.validations.safeToFlyValid;
                default:
                  return true;
              }
            },

            consignmentData() {
                const methodOfSupply = this.collectionStore.collectionData.methodOfSupply;

                if (methodOfSupply === 'COLLECTION') {
                    return {
                        orderType: methodOfSupply,
                        collectionUDPRN: this.collectionStore.collectionData.collectionAddress.id,
                        collectionAddress: Object.values(this.collectionStore.collectionData.collectionAddress).join(","),
                        collectionDate: this.collectionStore.collectionData.collectionDate.split(/[T ]/i, 1)[0],
                        collectionSafePlace: this.collectionStore.collectionData.safePlace
                    };
                } else {
                    return {
                        orderType: methodOfSupply
                    };
                }
            },
            
            orderData() {
                const today = new Date();
                const isoDate = today.toISOString().split('T')[0];
                const totalWeightConverted = this.mainStore.selected.weight / 1000;

                let docsOnly = '';
                let docsBoolean = null;

                if(this.mainStore.selected.sendingGoods == 'no goods' || this.mainStore.selected.sendingGoods  == null) {
                    docsOnly = 'DOX'
                    docsBoolean = true;

                } else {
                    docsOnly = 'NDX'
                    docsBoolean = false;
                }

                // let exportLicenseRequired = 'false';

                // if(this.mainStore.selected.vatRegistered == 'true') {
                //     exportLicenseRequired = 'true'
                // }

                let goodsDescription = '';

                if(this.mainStore.selected.category === '') {
                    goodsDescription = this.mainStore.selected.parcelSize.itemFormat;
                } else {
                    goodsDescription = this.mainStore.selected.category;
                }

                let shipperReference = this.mainStore.selected.shipperRef;
//
                if(shipperReference == '') {
                    // Asked to leave blank but system requires a value to pass server side validation checks
                    shipperReference = 'N/A';
                }

                const modifiedCustomsItems = this.mainStore.selected.customsItems.map(item => {
                    const newItem = { ...item };
                    if (this.mainStore.selected.sendingGoods == 'no goods' || this.mainStore.selected.sendingGoods == null) {
                        delete newItem.itemLines.ITEMDESCRIPTION;
                    }

                    // Convert all values to strings
                    Object.keys(newItem.itemLines).forEach(key => {
                        if (newItem.itemLines[key] !== null && !isNaN(newItem.itemLines[key])) {
                            newItem.itemLines[key] = newItem.itemLines[key].toString();
                        }
                    });

                    return newItem;
                });

                // Temporary solution to nested address format. I'm sure I can think of a better way to handle this but I'm rushing. Should really be flattened at source.

                // helper function for nested possibility
                const getAddressValue = (address, key) => {
                    return address?.[key] || address?.address?.[key] || '';
                };

                const senderDetails = this.mainStore.selected.sendersDetails.address;
                const addressLine1 = getAddressValue(senderDetails, 'ADDRESSLINE1');
                const addressLine2 = getAddressValue(senderDetails, 'ADDRESSLINE2');
                const addressLine3 = getAddressValue(senderDetails, 'ADDRESSLINE3');
                const addressCity = getAddressValue(senderDetails, 'CITY');
                const addressCounty = getAddressValue(senderDetails, 'COUNTY');
                const addressPostcode = getAddressValue(senderDetails, 'POSTCODE');

                return {
                    consignmentRef: this.basketStore.consignmentData.consignmentRef,
                    itemFormat: this.mainStore.selected.parcelSize.itemFormat,
                    source: 'ONLINE_POSTAGE',
                    shipmentFormat: {
                        name: "",
                        shipperLines: {
                            SHIPPERNAME: this.mainStore.sendersFullName,
                            //SHIPPERCOMPANY: 'placeholder',
                            SHIPPERADDRESSLINE1: addressLine1,
                            SHIPPERADDRESSLINE2: addressLine2,
                            SHIPPERADDRESSLINE3: addressLine3,
                            SHIPPERPHONE: this.mainStore.selected.sendersDetails.telephone || '',
                            SHIPPEREMAIL: this.mainStore.selected.sendersDetails.emailAddress || '',
                            SHIPPERREFERENCE: shipperReference,
                            SHIPPERVATNUMBER: this.mainStore.selected.vatNumber || '',
                            SHIPPEREORINUMBER: this.mainStore.selected.eoriNumber || '',
                            //SHIPPERPPI: "DO066",
                            SHIPPERREFERENCE2: '',
                            SHIPPERCITY: addressCity,
                            SHIPPERPOSTCODE: addressPostcode,
                            SHIPPERCOUNTY: addressCounty,
                            SHIPPERCOUNTRY: 'IM'
                        },
                        recipientLines: {
                            RECIPIENTNAME: this.mainStore.recipientFullName,
                            RECIPIENTCOMPANY: this.mainStore.selected.recipientDetails.address?.RECIPIENTORG || '',
                            RECIPIENTPHONE: this.mainStore.selected.recipientDetails.telephone || '',
                            RECIPIENTEMAIL: this.mainStore.selected.recipientDetails.address.RECIPIENTEMAIL || '',
                            //RECIPIENTVATNUMBER: "",
                            //RECIPIENTPUDO: "",
                            RECIPIENTADDRESSLINE1: this.mainStore.selected.recipientDetails.address?.RECIPIENTADDRESSLINE1 || '',
                            RECIPIENTADDRESSLINE2: this.mainStore.selected.recipientDetails.address?.RECIPIENTADDRESSLINE2 || '',
                            RECIPIENTADDRESSLINE3: this.mainStore.selected.recipientDetails.address?.RECIPIENTADDRESSLINE3 || '',
                            RECIPIENTCITY: this.mainStore.selected.recipientDetails.address?.RECIPIENTCITY || '',
                            RECIPIENTPOSTCODE: this.mainStore.selected.recipientDetails.address?.RECIPIENTPOSTCODE || '',
                            RECIPIENTCOUNTY: this.mainStore.selected.recipientDetails.address?.RECIPIENTCOUNTY || '',
                            RECIPIENTCOUNTRY: this.mainStore.selected.destination.webRiposteCode
                        },
                        shipmentLines: {
                            ACCOUNTNAME: this.mainStore.selected.serviceDetails.accountName,
                            SHIPMENTDATE: isoDate,
                            SERVICECODE: this.mainStore.selected.serviceDetails.serviceCode,
                            CONTROLCODE: this.mainStore.selected.serviceDetails.controlCode,
                            SERVICELEVEL: this.mainStore.selected.serviceDetails.serviceLevel,
                            SERVICEFORMAT: this.mainStore.selected.serviceDetails.serviceFormat,
                            PRODUCT: docsOnly,
                            DOCSONLY: docsBoolean ? "true" : "false",
                            TERMS: "DDU",
                            TOTALWEIGHT: totalWeightConverted.toString(),
                            DECLAREDVALUE: this.mainStore.customsTotalValue,
                            DESCRIPTIONOFGOODS: goodsDescription,
                            SAFEPLACE: this.collectionStore.collectionData.safePlace,
                            //COMMENTS: "My comments",
                            REASONFOREXPORT: this.mainStore.selected.category,
                            //SATURDAYDELIVERY: "",
                            ENHANCEDCOMPENSATION: "",
                            INSUREDVALUE: this.mainStore.selected.additionalInsurance.value?.toString() || '0'
                        },
                        packages: [
                            {
                                packageLines: {
                                    ID: "0",
                                    SHIPPINGCOST: this.mainStore.customsTotalValue?.toString() || '',
                                    INVOICENUMBER: "789123",
                                    // EXPORTLICENCENUMBER: this.mainStore.exportLicenseNumber,
                                    // EXPORTLICENSEREQUIRED: exportLicenseRequired,
                                    // CERTIFICATENUMBER: this.mainStore.selected.certNumber.toString() || ''
                                },
                                items: modifiedCustomsItems
                            }
                        ]
                    }
                };

            }
        },
        methods: { 

            async createConsignment() {
                try {
                    // Set isLoading to true before making the API call
                    this.isLoading = true;
                    this.mainStore.pageLoading = true;

                    const payload = this.consignmentData;
                    // const xAuthToken = await this.mainStore.retrieveXAuthToken();

                    const response = await axios.post(process.env.VUE_APP_API_CREATECONSIGNMENT, payload, { 
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': process.env.VUE_APP_AUTHORIZATION,
                            // 'X-Auth-Token': xAuthToken
                        }
                    });

                    this.consignmentResponse = response.data;
                    this.basketStore.consignmentData = response.data;

                    // Set isLoading back to false after API call completes
                    this.isLoading = false;
                    this.mainStore.pageLoading = false;

                    return true; // Return true to indicate successful API call
                } catch (error) {
                    console.error(error);
                    alert('Create consignment failed');
                    this.consignmentError = true;
                    this.errorMessage = 'Failed to create consignment. Please try again.';
                    this.resetStores;

                    // Set isLoading back to false after API call completes
                    this.isLoading = false;

                    // Remove loader
                    this.mainStore.pageLoading = false;

                    // Reset the collection data
                    this.collectionStore.resetCollection;

                    return false; // Return false to indicate failed API call
                }
            },

            async createShipment() {
                try {
                    // Set isLoading to true before making the API call
                    this.isLoading = true;
                    this.mainStore.pageLoading = true;

                    const payload = this.orderData;
                    // const xAuthToken = this.mainStore.tokens.xauthtoken;

                    // if (!xAuthToken) {
                    //     return null;
                    // }

                    const response = await axios.post(process.env.VUE_APP_API_CREATE_SHIPMENT, payload, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': process.env.VUE_APP_AUTHORIZATION,
                            // 'X-Auth-Token': xAuthToken
                        }
                    });

                    // Set isLoading back to false after API call completes
                    this.isLoading = false;
                    this.mainStore.pageLoading = false;
                    this.basketStore.lastUpdated = new Date();
                    this.collectionStore.lastUpdated = new Date();

                    return response.data;  // Return the response data

                } catch (error) {
                    console.error(error);
                    this.$router.push({
                        path: '/confirmation-error'
                    });

                    // Set isLoading back to false after API call completes
                    this.isLoading = false;
                    this.mainStore.pageLoading = false;

                    return null;
                }
            },

            async handleNextClick() {
                if (!this.pageIsValid || this.isLoading) {
                    return;
                }

                if (
                    (this.$route.name === 'sender' && this.mainStore.selected.destination.alpha3Code === 'IMN') ||
                    (this.$route.name === 'sender' && this.mainStore.selected.destination.alpha3Code === 'GBR') ||
                    (this.$route.name === 'customs' && this.mainStore.selected.destination.alpha3Code !== 'IMN')
                ) {
                    this.isLoading = true;
                    this.mainStore.pageLoading = true;
                    
                    if (this.basketStore.basketCount === 0) {
                        const consignmentSuccess = await this.createConsignment();
                        if (!consignmentSuccess) {
                            this.isLoading = false;
                            this.mainStore.pageLoading = false;
                            return;
                        }
                    }

                    try {
                        /* global fbq */
                        fbq('track', 'AddToCart', {
                            content_name: this.mainStore.selected.service || 'Unknown Service',
                            content_ids: this.mainStore.selected.serviceDetails?.accountName || 'Unknown Id',
                            content_type: 'Service',
                            value: this.mainStore.selected.serviceDetails?.priceInPence || 0,
                            currency: 'GBP'
                        });
                    } catch {
                        console.log("Analytics disabled")
                    }

                    const apiResponse = await this.createShipment();
                    if (apiResponse && apiResponse.shipmentRef) {
                        this.$router.push(this.buttonLinkNext);
                        this.mainStore.selected.shipmentRef = apiResponse.shipmentRef;
                        this.basketStore.basketItems.push(this.mainStore.selected);
                    }

                    this.isLoading = false;
                    this.mainStore.pageLoading = false;
                } else {
                    this.$router.push(this.buttonLinkNext);
                }
            },

            async addToBasket() {
                this.isLoading = true;
                this.mainStore.pageLoading = true;
                
                if (this.basketStore.basketCount === 0) {
                    const consignmentSuccess = await this.createConsignment();
                    if (!consignmentSuccess) {
                        this.isLoading = false;
                        this.mainStore.pageLoading = false;
                        return;
                    }
                }

                const shipmentResponse = await this.createShipment();
                if (shipmentResponse && shipmentResponse.shipmentRef) {
                    this.mainStore.selected.shipmentRef = shipmentResponse.shipmentRef;
                    this.basketStore.basketItems.push(this.mainStore.selected);
                }

                this.isLoading = false;
                this.mainStore.pageLoading = false;
            }
        },

        beforeRouteLeave(to, from, next) {
            // Check if 'to' is null or undefined, or if it lacks the 'path' property
            if (!to || typeof to !== 'object' || !('path' in to)) {
                // If 'to' is null, undefined, or has no 'path', allow navigation
                next();
                return;
            }

            // Check if the next route is the one triggered by the "Next" button and if the current route is 'provideItems'
            if (to.path === this.buttonLinkNext && this.$route.name === 'provideItems') {
                // Prevent default navigation behavior
                next(false);
                // Navigate only when the API call completes
                this.navigateToNextRoute();
            } else {
                // Allow navigation for other routes
                next();
            }
        },

        resetStores() {
            this.basketstore.resetBasket();
            this.collectionstore.resetCollection();
            this.mainstore.reset();
        }
    }
</script>

