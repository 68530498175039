<template>
    <div class="contact-manager">
        <ToastNotification ref="toast"/>
        <span class="contact-manager.close" @click="handleClose"></span>
        <div class="contact-manager.content">
            <div class="contact-manager.content.column-one">
                <h1 class="contact-manager.content.column-one.title">Contacts <br/><sup class="contact-manager.content.column-one.title.count">{{ addressBookCount }} / 100 <span class="contact-manager.content.column-one.title.count.remaining">({{ remainingContacts }} remaining)</span></sup></h1>
                <div class="contact-manager.content.column-one.button">
                    <MainButton btntxt="Add contact" class="--dark --spacing" @click="addContact = true" v-if="!addContact"/>
                    <MainButton btntxt="Cancel" class="--dark --spacing" @click="hideForm()" v-if="addContact" />
                </div>
                <label for="search" class="form-label --dark">Search</label>
                <input 
                    id="search"
                    class="form-text-field contact-manager.search"
                    name="Search"
                    v-model="searchQuery" 
                    placeholder="Search contacts..." 
                    @click="hideForm"
                />
            </div>
            <div class="contact-manager.content.column-two">
                <div v-if="addContact" class="contact-manager.content.column-two.form">
                    <fieldset class="contact-manager.content.column-two.form.fieldset">
                        <h2 class="contact-manager.content.column-two.form.fieldset.title">Add a contact</h2>
                        <label for="country" class="form-label">Where is this contact located?</label>
                        <select 
                            name="country"
                            id="country"
                            class="form-select-field"
                            v-model="destinationCountry"
                            @change="fetchAddressPayload"
                        >
                            <optgroup label="Most popular">
                                <option
                                    v-for="option in mostPopular"
                                    :key="option.alpha3Code"
                                    :value="option"
                                    :selected="option.webRiposteCode === 'UK'"
                                >
                                    {{ option.name }}
                                </option>
                            </optgroup>
                            <optgroup label="Other countries">
                                <option
                                    v-for="option in countries"
                                    :key="option.id"
                                    :value="option"
                                    class="form-select-field.option"
                                >
                                    {{ option.name }}
                                </option>
                            </optgroup>
                        </select>
                        <TypeaheadAddress 
                            v-if="['IM', 'UK', 'GK', 'JE'].includes(destinationCountry.webRiposteCode)" 
                            @changed="addAddress"
                            :region="destinationCountry.alpha3Code"
                        />
                        <ContactAddressForm 
                            v-else
                            @valid="validateAddress()" 
                            @invalid="invalidateAddress()"
                            :payload="addressPayload"
                            :address="newContact"
                            @changed="handleFormChange"
                        />

                        
                        <div class="recipient-details" v-if="newContact.POSTCODE">
                            <h2 class="recipient-details.title">Contact details</h2>
                            <div class="recipient-details.business-container">
                            <ToggleSwitch
                                :value="isBusiness"
                                selectedMessage="This contact is a business"
                                unselectedMessage="This contact is not a business"
                                @toggle="setIsBuisness"></ToggleSwitch>
                            </div>
                            <br/>
                            <div v-if="isBusiness">
                            <label for="recipientFirstName" class="form-label">Business name<span class="form-label.required">*</span></label>
                            <div class="form-text-field.container" :class="{ '--valid': businessNameValid === true}">
                                <input 
                                type="text" 
                                class="form-text-field" 
                                name="recipientFirstName" 
                                v-model.trim="newContact['ORG']" 
                                @blur="validateBusinessName(true)"
                                />
                                <p class="error" v-if="businessNameTouched && businessNameValid === false">You must enter a business name</p>
                            </div>
                            </div>
                            <label for="recipientFirstName" class="form-label">First name<span class="form-label.required" v-if="!isBusiness">*</span></label>
                            <div class="form-text-field.container" :class="{ '--valid': firstNameValid === true}">
                            <input 
                                type="text" 
                                class="form-text-field" 
                                name="recipientFirstName" 
                                v-model.trim="newContact.FIRSTNAME" 
                                @blur="validateFirstName(true)"
                            />
                            </div>
                            <p class="error" v-if="firstNameTouched && firstNameValid === false">You must enter a first name greater than two characters in length</p>
                            <label for="recipientLastName" class="form-label">Last name<span class="form-label.required" v-if="!isBusiness">*</span></label>
                            <div class="form-text-field.container" :class="{ '--valid': lastNameValid === true}">
                            <input 
                                type="text" 
                                class="form-text-field" 
                                name="recipientLastName" 
                                v-model.trim="newContact.LASTNAME" 
                                @blur="validateLastName(true)"
                            />
                            </div>
                            <p class="error" v-if="lastNameTouched && lastNameValid === false">You must enter a last name greater than two characters in length</p>
                            <p class="error" v-if="firstNameTouched && lastNameTouched && fullNameValid === false">Both the first and last name must be less than 40 characters combined</p>
                            <div>
                                <label for="recipientContactTelephone" class="form-label">Recipient contact telephone<span class="form-label.required">*</span></label>
                                <div class="form-text-field.container" :class="{ '--valid': telephoneValid === true}">
                                <input 
                                    type="tel" 
                                    class="form-text-field"
                                    name="recipientContactTelephone" 
                                    v-model.trim="newContact.TELEPHONE" 
                                    @blur="validateTelephone"
                                />
                                </div>
                                <p class="error" v-if="telephoneTouched && telephoneValid === false">You must enter a valid telephone number</p>
                            </div>
                        </div>
                        <div class="contact-manager.content.column-two.contacts.contact --new-contact" v-if="newContact.ADDRESSLINE1">
                            <span class="contact-manager.content.column-two.contacts.contact.name" v-if="newContact.FIRSTNAME && newContact.LASTNAME">{{ capitalizeWords((newContact.FIRSTNAME || '') + ' ' + (newContact.LASTNAME || '')) }}</span>
                            <div class="contact-manager.content.column-two.contacts.contact.address">
                                <span v-if="newContact.ORG" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(newContact.ORG) }}</span>
                                <span v-if="newContact.ADDRESSLINE1" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(newContact.ADDRESSLINE1) }}</span>
                                <span v-if="newContact.ADDRESSLINE2" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(newContact.ADDRESSLINE2) }}</span>
                                <span v-if="newContact.ADDRESSLINE3" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(newContact.ADDRESSLINE3) }}</span>
                                <span v-if="newContact.CITY" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(newContact.CITY) }}</span>
                                <span v-if="newContact.COUNTY" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(newContact.COUNTY) }}</span>
                                <span v-if="destinationCountry.name" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(destinationCountry.name) }}</span>
                                <span v-if="newContact.POSTCODE" class="contact-manager.content.column-two.contacts.contact.address.line">{{ newContact.POSTCODE }}</span>
                            </div>
                            <span class="contact-manager.content.column-two.contacts.contact.telephone" v-if="newContact.TELEPHONE">{{ newContact.TELEPHONE }}</span>
                        </div>
                        <MainButton btntxt="Add contact" :class="{ '--disabled': validations.contactDetailsValid === false || validations.contactDetailsValid === null || addressBookCount >= 100 }" @click="addContactRecord"/>
                    </fieldset>
                </div>
                <!--
                <div v-if="editingContact" class="contact-manager.content.column-two.form">
                    Hello
                </div>
                -->
                <div v-else class="contact-manager.content.column-two.contacts">
                    <div v-for="contact in filteredContacts" :key="contact.id" class="contact-manager.content.column-two.contacts.contact" ref="contactRefs">
                        <span class="contact-manager.content.column-two.contacts.contact.name" v-if="contact.FIRSTNAME && contact.LASTNAME">{{ capitalizeWords((contact.FIRSTNAME || '') + ' ' + (contact.LASTNAME || '')) }}</span>
                        <div class="contact-manager.content.column-two.contacts.contact.address">
                            <span v-if="contact.ORG" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(contact.ORG) }}</span>
                            <span v-if="contact.ADDRESSLINE1" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(contact.ADDRESSLINE1) }}</span>
                            <span v-if="contact.ADDRESSLINE2" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(contact.ADDRESSLINE2) }}</span>
                            <span v-if="contact.ADDRESSLINE3" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(contact.ADDRESSLINE3) }}</span>
                            <span v-if="contact.CITY" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(contact.CITY) }}</span>
                            <span v-if="contact.COUNTY" class="contact-manager.content.column-two.contacts.contact.address.line">{{ contact.COUNTY }}</span>
                            <span v-if="contact.countryName" class="contact-manager.content.column-two.contacts.contact.address.line">{{ capitalizeWords(contact.countryName) }}</span>
                            <span v-if="contact.POSTCODE" class="contact-manager.content.column-two.contacts.contact.address.line">{{ contact.POSTCODE }}</span>
                        </div>
                        <span class="contact-manager.content.column-two.contacts.contact.telephone" v-if="contact.TELEPHONE">{{ contact.TELEPHONE }}</span>
                        <div class="contact-manager.content.column-two.contacts.contact.buttons">
                            <!--
                            
                                <button @click="editContact(contact.id)" class="contact-manager.content.column-two.contacts.contact.buttons.button">Edit contact</button>
                            -->
                            <button @click="deleteContact(contact.id)" class="contact-manager.content.column-two.contacts.contact.buttons.button">Delete contact</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainButton from './MainButton.vue';
import anime from 'animejs';
import TypeaheadAddress from './TypeaheadAddress.vue';
import axios from 'axios';
import ContactAddressForm from './ContactAddressForm.vue';
import ToggleSwitch from './form-components/ToggleSwitch.vue';
import { useMainStore } from '@/stores/MainStore.ts';
import ToastNotification from './ToastNotification.vue';


    export default {
        name: 'ContactManager',

        components: {
            MainButton,
            TypeaheadAddress,
            ContactAddressForm,
            ToggleSwitch,
            ToastNotification
            
        },

        emits: ['close'],

        setup() {
            const mainstore = useMainStore();

            return {
                mainstore
            };
        },

        data() {
            return {
                searchQuery: '',
                addContact: false,
                editingContact: false,
                destinationCountry: '',
                countries: [],
                addressPayload: [],
                selectedddress: {},
                addressValid: null,
                fullNameValid: null,
                firstNameValid: false,
                lastNameValid: false,
                telephoneValid: false,
                businessNameValid: false,
                validations: {
                    contactDetailsValid: false
                },
                isBusiness: false,
                businessNameTouched: false,
                firstNameTouched: false,
                lastNameTouched: false,
                telephoneTouched: false,
                newContact: [],
                mostPopular: [
                    { alpha2Code: 'IM', alpha3Code: 'IMN', name: 'Isle of Man', numericCode: '833', parcelForceCode: 'GB', region: 'Europe', webRiposteCode: 'IM' },
                    { alpha2Code: 'GB', alpha3Code: 'GBR', name: 'United Kingdom', numericCode: '826', parcelForceCode: 'GB', region: 'Europe', webRiposteCode: 'UK' },
                    { alpha2Code: 'GB', alpha3Code: 'GBR', name: 'Northern Ireland', numericCode: '826', parcelForceCode: 'GB', region: 'Europe', webRiposteCode: 'UK' },
                    { alpha2Code: 'IE', alpha3Code: 'IRL', name: 'Ireland', numericCode: '372', parcelForceCode: 'IE', region: 'Europe', webRiposteCode: 'EI' },
                    { alpha2Code: 'AU', alpha3Code: 'AUS', name: 'Australia', numericCode: '036', parcelForceCode: 'AU', region: 'Oceania', webRiposteCode: 'AS' },
                    { alpha2Code: 'CA', alpha3Code: 'CAN', name: 'Canada', numericCode: '124', parcelForceCode: 'CA', region: 'Americas', webRiposteCode: 'CA' },
                    { alpha2Code: 'FR', alpha3Code: 'FRA', name: 'France', numericCode: '250', parcelForceCode: 'FR', region: 'Europe', webRiposteCode: 'FR' },
                    { alpha2Code: 'DE', alpha3Code: 'DEU', name: 'Germany', numericCode: '276', parcelForceCode: 'DE', region: 'Europe', webRiposteCode: 'GM' },
                    { alpha2Code: 'ES', alpha3Code: 'ESP', name: 'Spain', numericCode: '724', parcelForceCode: 'ES', region: 'Europe', webRiposteCode: 'SP' },
                    { alpha2Code: 'US', alpha3Code: 'USA', name: 'United States of America', numericCode: '840', parcelForceCode: 'US', region: 'Americas', webRiposteCode: 'US' }
                ]
            }
        },

        watch: {
            filteredContacts() {
                this.animateContacts();
            }
        },

        computed: {
            filteredContacts() {
                //console.log(this.addresses);
                const query = this.searchQuery.toLowerCase();

                const filtered = this.addresses.filter(contact => {
                    return (
                        (contact.ORG && contact.ORG.toLowerCase().includes(query)) ||
                        (contact.FIRSTNAME && contact.FIRSTNAME.toLowerCase().includes(query)) ||
                        (contact.LASTNAME && contact.LASTNAME.toLowerCase().includes(query)) ||
                        (contact.ADDRESSLINE1 && contact.ADDRESSLINE1.toLowerCase().includes(query)) ||
                        (contact.ADDRESSLINE2 && contact.ADDRESSLINE2.toLowerCase().includes(query)) ||
                        (contact.ADDRESSLINE3 && contact.ADDRESSLINE3.toLowerCase().includes(query)) ||
                        (contact.CITY && contact.CITY.toLowerCase().includes(query)) ||
                        (contact.POSTCODE && contact.POSTCODE.toLowerCase().includes(query)) ||
                        (contact.COUNTY && contact.COUNTY.toLowerCase().includes(query)) ||
                        (contact.TELEPHONE && contact.TELEPHONE.toLowerCase().includes(query))
                    );
                });

                return filtered.sort((a, b) => {
                    const lastnameA = a.LASTNAME ? a.LASTNAME.toLowerCase() : '';
                    const lastnameB = b.LASTNAME ? b.LASTNAME.toLowerCase() : '';
                    if (lastnameA < lastnameB) return -1;
                    if (lastnameA > lastnameB) return 1;
                    return 0;
                });
            },

            addresses() {
                return this.mainstore.user.user.addressBook 
                    ? Object.values(this.mainstore.user.user.addressBook) 
                    : [];
            },

            addressBookCount() {
                return this.mainstore.getAddressBookCount();
            },

            remainingContacts() {
                return 100 - this.mainstore.getAddressBookCount();
            }
        },


        methods: {
            hideForm() {
                this.addContact = false;
                this.resetContactForm();
            },

            capitalizeWords(str) {
                let words = str.toLowerCase().split(" ");
                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
                }
                return words.join(" ");
            },

            animateContacts() {
                anime({
                    targets: this.$refs.contactRefs,
                    opacity: [0, 1],
                    translateX: [50, 0],
                    delay: anime.stagger(100),
                    easing: 'easeOutQuad',
                    duration: 500
                });
            },

            editContact(){
                this.editingContact = true;
            },

            deleteContact(contactId) {
                if (confirm("Are you sure you want to delete this contact?")) {
                    const addressBook = this.mainstore.user.user.addressBook;

                    if (Array.isArray(addressBook)) {
                        const updatedAddressBook = addressBook.filter(contact => contact?.id !== contactId);

                        const data = {
                            ...this.mainstore.user.user,
                            addressBook: updatedAddressBook,
                        };

                        const token = this.mainstore.user.jwt;

                        axios.put(process.env.VUE_APP_API_UPDATE, data, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        })
                        .then(response => {
                            console.log('Contact deleted successfully:', response.data);
                            this.mainstore.user.user = response.data;
                        })
                        .catch(error => {
                            console.error('Error updating addressBook:', error);
                        });
                    } 
                }
            },


            getCountries() {
                axios.get(process.env.VUE_APP_API_COUNTRIES, {
                    headers: {
                        'Authorization': process.env.VUE_APP_AUTHORIZATION,
                    }
                })
                .then(response => {
                    this.countries = response.data.countryList.sort((a, b) => a.name.localeCompare(b.name));
                })
                .catch(err => console.error(err));
            },

            fetchAddressPayload() {
                this.newContact = [];
                //this.addressValid = false;
                this.validations.contactDetailsValid = false;
                axios({
                method: 'get',
                url: process.env.VUE_APP_API_CUSTOMSFORMAT,
                params: {
                    product: 'STracked48SPcl',
                    country: this.destinationCountry.alpha3Code,
                    itemformat: 'SMALL_PARCEL'

                },
                headers: {
                    'Authorization': process.env.VUE_APP_AUTHORIZATION,
                }
                })
                .then(response => {
                this.addressPayload = response.data.recipientLines;
                delete this.addressPayload.RECIPIENTCOMPANY;
                })
                .catch(err => {
                console.error('Error fetching address payload:', err);
                });
            },
            
            addAddress(selectedValue) {
                console.log(selectedValue);

                selectedValue.countryName = this.destinationCountry.name;

                this.newContact = {
                    ...selectedValue.address, 
                    id: `contact_${Date.now()}`,
                    country: selectedValue.country,
                    countryName: selectedValue.countryName
                };

                this.addressValid = true;
            },


            /*
            addAddress(selectedValue) {
                selectedValue.countryName = this.destinationCountry.name;

                this.newContact = {
                    ...selectedValue.address, 
                    id: selectedValue.id,
                    country: selectedValue.country,
                    countryName: selectedValue.countryName
                };

                this.addressValid = true;
            },
            */
            /*
            addAddress(selectedValue) {
                console.log(selectedValue)

                selectedValue.countryName = this.destinationCountry.name;

                const exists = this.addresses.some(existingAddress => 
                    existingAddress.ADDRESSLINE1 === selectedValue.address.ADDRESSLINE1 &&
                    existingAddress.CITY === selectedValue.address.CITY &&
                    existingAddress.POSTCODE === selectedValue.address.POSTCODE
                );

                if (!exists) {
                    this.newContact = {
                        ...selectedValue.address, 
                        id: selectedValue.id,
                        country: selectedValue.country,
                        countryName: selectedValue.countryName
                    };
                } else {
                    alert('This address is already in your contacts.');
                }

                this.addressValid = true;
            },
            */


            handleFormChange(transformedData) {
                transformedData.countryName = this.destinationCountry.name;
                transformedData.country = this.destinationCountry.alpha3Code;
                this.newContact = transformedData;
            },

            setIsBuisness() {
                this.isBusiness = !this.isBusiness;

                var firstName = this.newContact.FIRSTNAME;
                var lastName = this.newContact.LASTNAME;

                this.businessNameTouched = this.newContact['ORG'] ? true : false;
                this.businessNameValid = this.newContact['ORG'] ? true : false;
                this.firstNameValid = firstName || firstName == '' ? true : false;
                this.lastNameValid = lastName || lastName == '' ? true : false;

                this.validateRecipient();
                this.validateRecipientDetails();
            },

            validateRecipient() {
                if (this.businessNameTouched) {
                    this.validateBusinessName(false);
                } 
                if (this.firstNameTouched) {
                    this.validateFirstName(false);
                }
                if (this.lastNameTouched) {
                    this.validateLastName(false);
                }

                this.validate();
            },

            validateFirstName(manualInput) {
                let data = this.newContact.FIRSTNAME || '';

                if (this.isBusiness) {
                    this.firstNameValid = data === '' || data.length >= 3;
                } else {
                    this.firstNameValid = data !== '' && data.length >= 3;
                }

                if (data || manualInput) {
                    this.firstNameTouched = true;
                }

                let lastName = this.newContact.LASTNAME || '';
                this.fullNameValid = (data.length + lastName.length) < 40;

                this.validate();
            },

            validateLastName(manualInput) { 
                let data = this.newContact.LASTNAME || '';
                console.log(data);

                if (this.isBusiness) {
                    this.lastNameValid = data === '' || data.length >= 3;
                } else {
                    this.lastNameValid = data !== '' && data.length >= 2;
                }

                if (data || manualInput) {
                    this.lastNameTouched = true;
                }

                let firstName = this.newContact.FIRSTNAME || ''; 
                this.fullNameValid = (data.length + firstName.length) < 40;

                this.validate();
            },

            validate() {
                var firstName = this.newContact.FIRSTNAME;
                var lastName = this.newContact.LASTNAME;

                if (!this.isBusiness) {
                this.validations.contactDetailsValid =
                    this.firstNameValid &&
                    this.lastNameValid &&
                    this.fullNameValid &&
                    this.telephoneValid &&
                    this.addressValid;
                } else if (firstName || lastName) {
                this.validations.contactDetailsValid =
                    this.businessNameValid &&
                    this.firstNameValid &&
                    this.lastNameValid &&
                    this.fullNameValid &&
                    this.telephoneValid &&
                    this.addressValid;
                } else {
                this.validations.contactDetailsValid =
                    this.businessNameValid &&
                    this.telephoneValid &&
                    this.addressValid;
                }
            },

            validateBusinessName(manualInput) {
                let data = this.newContact['ORG'];
                if (this.isBusiness && data) {
                    this.newContact.ORG = data;
                    this.businessNameValid = data != '' && data.length >= 1;
                } else {
                    this.businessNameValid = false;
                }
                if (data || manualInput) {
                    this.businessNameTouched = true;
                }
                this.validate();
            },

            validateRecipientDetails() {
                if (this.isBusiness) {
                    this.businessNameTouched = false;
                    this.validateBusinessName();
                    this.fullNameValid = true;
                } else {
                    this.fullNameValid = false;
                    this.firstNameTouched = false;
                    this.validateFirstName(false);
                    this.lastNameTouched = false;
                    this.validateLastName(false);
                }
                this.validate();
            },

            validateTelephone() {
                let data = this.newContact.TELEPHONE;
                // eslint-disable-next-line
                this.telephoneValid = /^[\(\)\+ 0-9]{10,20}$/.test(data);
                this.telephoneTouched = true;
                this.validate();
            },

            validateAddress() {
                //this.validate();
                //if (this.newContact['ORG']) {
                //    this.isBusiness = true;
                //} else {
                //    this.isBusiness = false;
                //}
                this.addressValid = true;
                //this.validateRecipientDetails();
            },
            
            invalidateAddress() {
                this.addressValid = false;
                this.validate();
            },

            saveContact() {
                const addressBookAsObject = this.mainstore.user.user.addressBook;

                const data = { 
                    ...this.mainstore.user.user, 
                    addressBook: addressBookAsObject 
                };

                console.log(data);
                const token = this.mainstore.user.jwt;

                axios.put(process.env.VUE_APP_API_UPDATE, data, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then(response => {
                    console.log('Response:', response.data);
                    this.mainstore.user.user = response.data;
                    this.$refs.toast.showToast("Contact successfully saved!");
                });
            },

            addContactRecord() {
                if (!Array.isArray(this.mainstore.user.user.addressBook)) {
                    this.mainstore.user.user.addressBook = [];
                }

                const contactWithId = { 
                    id: `contact_${Date.now()}`, 
                    ...this.newContact 
                };

                this.mainstore.user.user.addressBook.push(contactWithId);

                this.saveContact();
                this.resetContactForm();
            },
            

            resetContactForm() {
                this.addContact = false;
                this.newContact = [];
                this.destinationCountry = '';
                this.addressValid = null;
                this.fullNameValid = null;
                this.firstNameValid = false;
                this.lastNameValid = false;
                this.telephoneValid = false;
                this.businessNameValid = false;
                this.validations.contactDetailsValid = false;
                this.isBusiness = false;
                this.businessNameTouched = false;
                this.firstNameTouched = false;
                this.lastNameTouched = false;
                this.telephoneTouched = false;
                this.addressPayload = [];
            }, 
            
            handleClose() {
                this.$emit('close');
            }
        },

        mounted() {
            this.animateContacts();
            this.getCountries();
        }
    }
</script>