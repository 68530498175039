<template>
    <div class="order-confirmation">
        <div v-if="confirmed === true">
            <PageTitle />
            <div class="container">
                <div class="content">
                    <div class="order-confirmation.order-details">
                        <span class="order-confirmation.order-details.intro">Your order</span>
                        <div class="order-confirmation.order-details.important-info">
                            <ul class="order-confirmation.order-details.important-info.list">
                                <li class="order-confirmation.order-details.important-info.list.item">
                                    <span class="order-confirmation.order-details.important-info.list.item.intro">Order
                                        ref: {{ shortenRef(orderData.consignmentRef) }}</span>
                                </li>
                                <li class="order-confirmation.order-details.important-info.list.item">
                                    <span class="order-confirmation.order-details.important-info.list.item.intro">Total
                                        paid: £{{(paymentData.totalPrice / 100).toFixed(2)}}</span>
                                </li>
                            </ul>
                            <!--
                            <div class="order-confirmation.order-details.important-info.button">
                                <MainButton btntxt="Download labels" @click="downloadLabels" />
                            </div>

                            -->
                        </div>
                        <div class="order-confirmation.order-details.list">
                            <div class="order-confirmation.order-details.list.item" v-for="item in orderData.shipments"
                                :key="item.shipmentRef">
                                <div class="order-confirmation.order-details.list.item.image">
                                    <img :src="require('@/assets/img/letter.png')" v-if="item.itemFormat == 'LETTER'" />
                                    <img :src="require('@/assets/img/large-letter.png')"
                                        v-if="item.itemFormat == 'LARGE_LETTER'" />
                                    <img :src="require('@/assets/img/parcel.png')"
                                        v-if="item.itemFormat == 'SMALL_PARCEL'" />
                                    <img :src="require('@/assets/img/package.png')"
                                        v-if="item.itemFormat == 'PARCEL'" />
                                </div>
                                <div class="order-confirmation.order-details.list.item.content">
                                    <h3 class="order-confirmation.order-details.list.item.content.title" v-if="item.itemFormat == 'LETTER'">
                                        Letter
                                    </h3>
                                    <h3 class="order-confirmation.order-details.list.item.content.title" v-if="item.itemFormat == 'LARGE_LETTER'">
                                        Large Letter
                                    </h3>
                                    <h3 class="order-confirmation.order-details.list.item.content.title" v-if="item.itemFormat == 'SMALL_PARCEL'">
                                        <!-- TODO: Remove with pricing engine -->
                                        <!-- Small Parcel -->
                                        Parcel
                                    </h3>
                                    <h3 class="order-confirmation.order-details.list.item.content.title" v-if="item.itemFormat == 'PARCEL'">
                                        Parcel
                                    </h3>
                                    <h4 class="order-confirmation.order-details.list.item.content.text">
                                        Service: {{ item.shipmentFormat.shipmentLines.ACCOUNTNAME }}
                                    </h4>
                                    <span class="order-confirmation.order-details.list.item.content.text">Going to
                                        {{ item.shipmentFormat.recipientLines.RECIPIENTNAME }} 
                                        <!--
                                            in
                                        {{ item.shipmentFormat.shipperLines.SHIPPERREFERENCE }}
                                        -->
                                    </span>
                                    <span class="order-confirmation.order-details.list.item.content.text">Shipping
                                        reference: {{ item.shipmentRef }}</span>
                                    <span>Price paid: £{{(itemPrices[item.shipmentRef] / 100).toFixed(2)}}</span>
                                </div>
                                <div class="order-confirmation.order-details.list.item.button">
                                    <MainButton 
                                        style="width: 200px" 
                                        btntxt="Download label"
                                        @click="downloadLabels(item.shipmentRef)" 
                                        v-if="orderData.orderType !== 'COLLECTION'"
                                    />
                                    <MainButton 
                                        style="width: 200px; margin-top: 10px;" 
                                        btntxt="Download customs"
                                        @click="downloadCustomsDocs(item.shipmentRef)"
                                        v-if="
                                            orderData.orderType !== 'COLLECTION' && 
                                            labelData && labelData.shipments && labelData.shipments.find(shipment => shipment.shipmentRef === item.shipmentRef && shipment.CN23 !== null)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="order-confirmation.supply-message">
                        <h1 class="order-confirmation.supply-message.maintitle">
                            Instructions
                        </h1>
                        <div v-if="orderData.orderType === 'COLLECTION'">
                            <h2 class="order-confirmation.supply-message.title">
                                For Collection
                            </h2>
                            <p class="order-confirmation.supply-message.intro-text">
                                You have chosen to have your items collected from:
                            </p>
                            <div class="order-confirmation.supply-message.section">
                                <!--
                                
                                <span class="order-confirmation.supply-message.address">
                                    <p v-if="collectionstore.collectionData.collectionAddress.address.ADDRESSLINE1">
                                        {{ capitalizeWords(collectionstore.collectionData.collectionAddress.address.ADDRESSLINE1) }}
                                    </p>
                                    <p v-if="collectionstore.collectionData.collectionAddress.address.ADDRESSLINE2">
                                        {{ capitalizeWords(collectionstore.collectionData.collectionAddress.address.ADDRESSLINE2) }}
                                    </p>
                                    <p v-if="collectionstore.collectionData.collectionAddress.address.ADDRESSLINE3">
                                        {{ capitalizeWords(collectionstore.collectionData.collectionAddress.address.ADDRESSLINE3) }}
                                    </p>

                                    <p v-if="collectionstore.collectionData.collectionAddress.address.CITY">
                                        {{ capitalizeWords(collectionstore.collectionData.collectionAddress.address.CITY) }}
                                    </p>
                                    <p v-if="collectionstore.collectionData.collectionAddress.address.POSTCODE">  
                                    {{ collectionstore.collectionData.collectionAddress.address.POSTCODE }}
                                    </p>
                                </span>
                                
                                -->
                                
                                <span class="order-confirmation.supply-message.address" v-if="orderData.orderType === 'COLLECTION'">
                                    <ul v-if="address" style="margin-bottom: 10px;">
                                        <li v-for="line in address.addresses[0].addressLines" :key="line">{{ line }}</li>
                                    </ul>

                                    <span v-if="orderData.collectionDate">on {{ formatTime(orderData.collectionDate) }}</span>
                                    <span v-else> within the next three working days</span>
                                </span>
                            </div>
                            <!--

                                <h3 class="order-confirmation.supply-message.subheading">
                                    Instructions
                                </h3>

                            -->
                            <h4 class="order-confirmation.supply-message.subsubheading">
                                Applying labels
                            </h4>
                            <ul class="order-confirmation.supply-message.list">
                                <li class="order-confirmation.supply-message.list.item">
                                    Your labels will be printed by Isle of Man Post Office, and
                                    will be brought to the collection address by one of our team
                                </li>
                                <li class="order-confirmation.supply-message.list.item">
                                    Please ensure that you have clearly marked each item with the
                                    shipping reference (as shown above for each item in your order) and the
                                    first line of the recipients address
                                </li>
                                <li class="order-confirmation.supply-message.list.item">
                                    Labels are 6”X4” (150mm x 100mm). Please ensure that each item
                                    is large enough to attach a label on one side.
                                </li>
                            </ul>
                            <h4 class="order-confirmation.supply-message.subsubheading">
                                Collection process
                            </h4>
                            <ul class="order-confirmation.supply-message.list">
                                <li class="order-confirmation.supply-message.list.item">
                                    You will have selected a collection date within three working
                                    days of making your purchase. Please ensure your items are
                                    available for us to collect on your chosen date.
                                </li>
                                <li class="order-confirmation.supply-message.list.item">
                                    During your Online Postage experience you will have been given
                                    the option to enter a safe place location where you plan to
                                    leave your item(s). Please ensure your items are in this
                                    location on the date you selected to have them collected, and
                                    are protected from the elements.
                                </li>
                            </ul>
                            <h4 class="order-confirmation.supply-message.subsubheading">
                                Important information
                            </h4>
                            <ul class="order-confirmation.supply-message.list">
                                <li class="order-confirmation.supply-message.list.item">
                                    If your items cannot be accessed at the collection address on
                                    the chosen date or multiple items are not marked with the
                                    recipient address, your collection will be cancelled.
                                </li>
                                <li class="order-confirmation.supply-message.list.item">
                                    If your collection is cancelled the postage labels will be
                                    left at your address. Apply the labels then drop your items at
                                    a Sub Post Office or Delivery Office Counter, but be mindful
                                    of the expiry date.
                                </li>
                            </ul>
                        </div>
                        <div v-else-if="orderData.orderType === 'DROP_AT_COUNTER'">
                            <h2 class="order-confirmation.supply-message.title">
                                Sub Post Office, Delivery Office or Parcel Collection Site drop
                                off
                            </h2>
                            <p class="order-confirmation.supply-message.intro-text">
                                You have chosen to drop your items off at a Sub Post Office,
                                Delivery Office or Parcel Collection Site
                            </p>
                            <div class="order-confirmation.supply-message.section">
                                <h3 class="order-confirmation.supply-message.subheading">
                                    Instructions
                                </h3>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Applying labels
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item">
                                        Please ensure each printed label is secured to one surface
                                        of each item
                                    </li>
                                    <li class="order-confirmation.supply-message.list.item">
                                        Avoid covering any detail on labels with adhesive tape
                                    </li>
                                    <li class="order-confirmation.supply-message.list.item">
                                    Further information regarding the application of labels can be found <router-link to="/labels">here</router-link>
                                </li>
                                </ul>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Finding a suitable Sub Post Office or Parcel Collection Site
                                    near you
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item">
                                        Sub Post Offices, Delivery Offices and Parcel Collection
                                        Sites can be found on the
                                        <a href="https://www.iompost.com/tools-forms/post-office-services-finder/" target="_blank">interactive
                                            Post Office Services map</a>
                                        along with their opening hours.
                                    </li>
                                    <li class="order-confirmation.supply-message.list.item">
                                        Note that some of our sites are open outside of normal
                                        office hours.
                                    </li>
                                </ul>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Expiry date
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item">
                                        Online Postage labels expire five working days after
                                        purchase. Make sure to post each item in time to reach
                                        Postal Headquarters before the expiry date indicated on the
                                        label. Be vigilant and aim to catch the last collection of
                                        the day!
                                    </li>
                                </ul>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Warning!
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item --warning">
                                        The ability to print your labels a second time exists, but
                                        is there to help should you have issues with your printer on
                                        the first attempt. Online Postage labels must only be used
                                        for postage once! Thorough checks are performed at Postal
                                        Headquarters that will identify duplicate labels, and lead
                                        to items being detained or delayed.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else-if="orderData.orderType === 'POST_LITE'">
                            <h2 class="order-confirmation.supply-message.title">
                                Printing your labels at a Post-Lite centre
                            </h2>
                            <p class="order-confirmation.supply-message.intro-text">
                                You have chosen to drop your items off at PostLite centre
                            </p>
                            <div class="order-confirmation.supply-message.section">
                                <h3 class="order-confirmation.supply-message.subheading">
                                    Instructions
                                </h3>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Finding a location offering Post–Lite where you can print your
                                    labels
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item">
                                        Locations offering Post-Lite can be found on the
                                        <a href="https://www.iompost.com/tools-forms/post-office-services-finder/" target="_blank">interactive
                                            Post Office Services map</a>
                                    </li>
                                    <li class="order-confirmation.supply-message.list.item">
                                        Note that locations offering Post-Lite services may be open
                                        outside of normal office hours
                                    </li>
                                </ul>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Applying labels
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item">
                                        Please ensure each printed label is secured to one surface
                                        of each item
                                    </li>
                                    <li class="order-confirmation.supply-message.list.item">
                                    Further information regarding the application of labels can be found <router-link to="/labels">here</router-link>
                                </li>
                                </ul>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Expiry date
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item">
                                        Online Postage labels expire five working days after
                                        purchase. Your labels must be printed and reach Postal
                                        Headquarters before the expiry date. Note that the expiry
                                        date commences from point of purchase, not point of printing
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else-if="orderData.orderType === 'DROP_AT_POSTBOX'">
                            <h2 class="order-confirmation.supply-message.title">
                                Post or Parcel Box Drop Off
                            </h2>
                            <p class="order-confirmation.supply-message.intro-text">
                                You have chosen to drop your items off at Post or Parcel Box
                            </p>
                            <div class="order-confirmation.supply-message.section">
                                <h3 class="order-confirmation.supply-message.subheading">
                                    Instructions
                                </h3>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Applying labels
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item">
                                        Please ensure each printed label is secured to one surface
                                        of the correct item
                                    </li>
                                    <li class="order-confirmation.supply-message.list.item">
                                        Avoid covering any detail on labels with adhesive tape
                                    </li>
                                    <li class="order-confirmation.supply-message.list.item">
                                    Further information regarding the application of labels can be found <router-link to="/labels">here</router-link>
                                </li>
                                </ul>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Dropping your items in a post box
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item">
                                        Find your nearest parcel or post box using
                                        <a href="https://www.iompost.com/tools-forms/post-office-services-finder/" target="_blank">Isle
                                            of Man Post Offices Post Office Services map</a>
                                    </li>
                                    <li class="order-confirmation.supply-message.list.item">
                                        If your item is too big to fit into a standard post box use
                                        a parcel box, or drop at any Sub Post Office or Delivery
                                        Office counter which can be found through
                                        <a href="https://www.iompost.com/tools-forms/post-office-services-finder/" target="_blank">Find
                                            your local Post Office services - Isle of Man Post
                                            Office (iompost.com)</a>
                                    </li>
                                </ul>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Expiry date
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item">
                                        Online Postage labels expire five working days after
                                        purchase. Make sure to post each item in time to reach
                                        Postal Headquarters before the expiry date indicated on the
                                        label. Be vigilant and aim to catch the last collection of
                                        the day!
                                    </li>
                                </ul>
                                <h4 class="order-confirmation.supply-message.subsubheading">
                                    Warning!
                                </h4>
                                <ul class="order-confirmation.supply-message.list">
                                    <li class="order-confirmation.supply-message.list.item --warning">
                                        The ability to print your labels a second time exists, but
                                        is there to help should you have issues with your printer on
                                        the first attempt. Online Postage labels must only be used
                                        for postage once! Thorough checks are performed at Postal
                                        Headquarters that will identify duplicate labels, and lead
                                        to items being detained or delayed.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="onward-journey">
                <h3 class="onward-journey.title">Where would you like to go next?</h3>
                <div class="onward-journey.buttons">
                    <router-link class="main-button" to="/" style="margin-bottom: 10px;"><span class="main-button.text">Back to homepage</span></router-link>
                    <a class="main-button" href="https://iompost.com"><span class="main-button.text">Isle of Man Post Office website</span></a>
                </div>
            </div>
        </div>
        <div v-else class="order-confirmation.loader">
            <LoaderIcon />
            <span class="order-confirmation.loader.text">Processing payment. Please wait</span>
        </div>
    </div>
</template>

<script>
import LoaderIcon from '@/components/form-components/LoaderIcon.vue';
import MainButton from '@/components/MainButton.vue';
import PageTitle from '@/components/PageTitle.vue';
import { useCollectionStore } from '@/stores/CollectionStore.ts';
import { useMainStore } from '@/stores/MainStore.ts';
import { useBasketStore } from '@/stores/BasketStore.ts';
import axios from 'axios';

export default {
    name: 'OrderConfirmation',

    components: {
        LoaderIcon,
        PageTitle,
        MainButton
    },

    setup() {
        const collectionstore = useCollectionStore();
        const mainstore = useMainStore();
        const basketstore = useBasketStore();

        return {
            collectionstore,
            mainstore,
            basketstore
        };
    },

    data() {
        return {
            confirmed: false,
            methodOfSupply: 'DROP_OFF_POSTBOX',
            orderData: {},
            paymentAuthorised: null,
            labelData: {},
            //customsDocsData: {},
            paymentData: {},
            labels: {},
            address: null,
            queryParameter: null,
            customs: false,
            shipments: null
        };
    },

    computed: {
        itemPrices() {
            const itemPrices = {};
            if (this.orderData.shipments && this.paymentData.itemisedPayments) {
                this.orderData.shipments.forEach((shipment) => {
                    const shipmentRef = shipment.shipmentRef;
                    const paidItem = this.paymentData.itemisedPayments.find(
                        (item) => item.id === shipmentRef
                    );
                    if (paidItem) {
                        itemPrices[shipmentRef] = paidItem.price;
                    } else {
                        itemPrices[shipmentRef] = 'n/a';
                    }
                });
            }
            return itemPrices;
        },
    },

    methods: {
        async getAuthorisationStatus() {
            try {
                //let token = this.mainstore.tokens.xauthtoken;

                //if (!token) {
                //    throw new Error('Token not available');
                //}

                const response = await axios({
                    method: 'get',
                    url: process.env.VUE_APP_API_PAYMENTAUTHORISED,
                    params: { orderCode: this.queryParameter },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': process.env.VUE_APP_AUTHORIZATION,
                        // 'X-Auth-Token': token
                    }
                });


                if (response.data.paymentStatus === 'AUTHORISED') {
                    await this.getConsignment();
                    this.getPaymentData();
                    this.getPrintConsignment();
                    //this.getPrintCustoms();
                    //this.getAddress();
                    this.trackPurchaseGoogle();
                    this.resetStores();
                    
                }

                this.paymentAuthorised = response.data;
                return response.data.paymentStatus;
            } catch (err) {
                console.error('Error in getAuthorisationStatus:', err);
                throw err;
            }
        },

        // Method to format date
        formatTime(time) {
            const formattedDate = new Date(time).toLocaleDateString([], {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
            return formattedDate;
        },

        // Method to capitalize words
        capitalizeWords(str) {
            let words = str.toLowerCase().split(' ');
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].slice(1);
            }
            return words.join(' ');
        },

        // Method to get consignment
        async getConsignment() {
            try {
                // let token = this.mainstore.tokens.xauthtoken;

                const response = await axios({
                    method: 'get',
                    url: process.env.VUE_APP_API_GETCONSIGNMENT,
                    params: {
                        consignmentRef: this.queryParameter
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': process.env.VUE_APP_AUTHORIZATION,
                    }
                });

                this.orderData = response.data;
            } catch (err) {
                console.error(err);
                this.$router.push({
                    path: '/confirmation-error'
                });
            }
        },
 
        /* async getAddress() {
            try {
                let data = this.orderData.collectionUDPRN;
                const response = await axios({
                    method: 'get',
                    url: `https://onlinepostage-uat.iompost.co.im/api/paf/v1/id/${data}`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': process.env.VUE_APP_AUTHORIZATION,
                    },
                });

                this.address = response.data;
            } catch (err) {
                console.error(err);
            }
        },*/

        async getPaymentData() {
            try {
                const response = await axios({
                    method: 'get',
                    url: process.env.VUE_APP_API_PAYMENTDETAILS,
                    params: {
                        orderCode: this.queryParameter
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': process.env.VUE_APP_AUTHORIZATION,
                    }
                });

                this.paymentData = response.data;
            } catch (err) {
                console.error(err);
                this.$router.push({
                    path: '/confirmation-error'
                });
            }
        },

        // Method to get label data
        async getPrintConsignment() {
            try {
                const response = await axios({
                    method: 'get',
                    url: process.env.VUE_APP_API_PRINTCONSIGNMENT,
                    params: {
                        consignmentRef: this.queryParameter
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': process.env.VUE_APP_AUTHORIZATION,
                    }
                });

                this.labelData = response.data;
                this.shipments = response.data.shipments;

            } catch (err) {
                console.error(err);
                this.$router.push({
                    path: '/confirmation-error'
                });
            }
        },

        /*
        async getPrintCustoms() {
            try {
                // let token = this.mainstore.tokens.xauthtoken;

                const response = await axios({
                    method: 'get',
                    url: process.env.VUE_APP_API_PRINTCUSTOMS,
                    params: {
                        consignmentRef: this.queryParameter
                    },
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': process.env.VUE_APP_AUTHORIZATION,
                        // 'X-Auth-Token': token
                    }
                });

                this.CustomsDocsData = response.data;
            } catch (err) {
                console.error(err);
                this.$router.push({
                    path: '/confirmation-error'
                });
            }
        }, */

        downloadLabels(shipmentRef) {
            const shipment = this.labelData.shipments.find((ship) => ship.shipmentRef === shipmentRef);

            if (shipment) {
                const base64Data = shipment.label;
                const blob = this.base64toBlob(base64Data);

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `label_${shipmentRef}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error(`Shipment with shipmentRef ${shipmentRef} not found.`);
            }
        },

        downloadCustomsDocs(shipmentRef) {
            const shipment = this.labelData.shipments.find((ship) => ship.shipmentRef === shipmentRef);

            if (shipment) {
                const base64Docs = shipment.CN23;
                const blob = this.base64toBlob(base64Docs);

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `customs_${shipmentRef}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error(`Shipment with shipmentRef ${shipmentRef} not found.`);
            }
        },
        
        base64toBlob(base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, { type: 'application/pdf' });
        },

        formatPrice(price) {
            return price.toFixed(2); // Format to 2 decimal places
        },

        shortenRef(value) {
            return value.slice(0, 10).toUpperCase();
        },

        resetStores() {
            this.basketstore.resetBasket();
            this.collectionstore.resetCollection();
            this.mainstore.fullReset();
        },

        trackPurchaseGoogle() {
            const items = this.orderData.shipments.map((shipment) => ({
                item_name: shipment.ACCOUNTNAME,
                item_id: shipment.shipmentRef,
                price: shipment.price / 100,
            }));

            window.dataLayer.push({
            event: "purchase",
            ecommerce: {
                transaction_id: this.orderData.consignmentRef,
                value: (this.paymentData.totalPrice || 0) / 100,
                currency: "GBP",
                items: items,
            },
            });
        }
    },

    // Call getAuthorisationStatus when the component is mounted
    mounted() {
        try {
            /* global fbq */
            fbq('track', 'Purchase', {
                value: (this.paymentData.totalPrice || 0) / 100,
                currency: 'GBP'
            })
        } catch {
            console.log("Analytics disabled")
        }

        if(this.$route.query.consignmentRef) {
            this.queryParameter = this.$route.query.consignmentRef;
        } else {
            this.queryParameter = this.basketstore.consignmentData.consignmentRef
        }

        const pollingDelays = [1,1,2,3,5,8,13,21,34,55,89,144,233,377,610];
        let currentDelayIdx = 0;
        try {
            const poll = () => {
                this.getAuthorisationStatus().then(status => {
                    if (status === 'AUTHORISED') {
                        this.confirmed = true;
                    } else {
                        if (currentDelayIdx + 1 === pollingDelays.length) {
                            this.$router.push({ path: '/confirmation-error' });
                        } else {
                            setTimeout(poll, pollingDelays[currentDelayIdx++] * 1000);
                        }
                    }
                }).catch(err => {
                    console.error(err);
                    this.$router.push({
                        path: '/confirmation-error'
                    });
                });
            };

            poll();
        } catch (error) {
            console.error(error);
            this.$router.push({
                path: '/confirmation-error'
            });
        }
    }

};
</script>
