<template>
    <div class="email-confirmed">
        <h1 class="email-confirmed.title">Thank you for verifying your email address</h1>
        <p class="email-confirmed.text">You now have access to use your Online Postage account.</p>
        <MainButton btntxt="Sign in" @click="openSignin"/>
    </div>
</template>

<script>
import MainButton from './MainButton.vue';
import { useMainStore } from "@/stores/MainStore.ts";

export default {
    name: 'EmailConfirmed',

    components: {
        MainButton
    },

    computed: {
        mainstore() {
            return useMainStore();
        },
    },

    methods: {
        openSignin() {
            this.mainstore.loginModal = true;
        },
    }
}

</script>