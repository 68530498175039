<template>
    <fieldset class="customs-item">
        <h3 class="customs-item.title">Item</h3>
        <label for="itemDescription" class="form-label">Item description<b class="form-label.required">*</b></label>
        <input 
            type="text" 
            name="itemDescription" 
            id="itemDescription" 
            class="form-text-field" 
            placeholder="Please describe your item(s)" 
            v-model="mainstore.selected.customsItems[itemIndex].itemLines.ITEMDESCRIPTION" 
            maxlength="30"
            @change="validateItemDescription()"
            max="30"
            min="4"
        />
        <p class="error" v-if="itemDescriptionValid === false">You must enter a description between 4 and 30 characters in length</p>
        <label for="originCountry" class="form-label">Country of origin<b class="form-label.required">*</b></label>
        <select 
            name="originCountry"
            id="originCountry"
            class="form-select-field"
            v-model="mainstore.selected.customsItems[itemIndex].itemLines.COUNTRYOFORIGIN"
            placeholder="Please select &hellip;"
            @change="validateOriginCountry()"
        >
        <option value="" disabled selected>Please choose &hellip;</option>
        <option
            v-for="option in countries"
            :key="option.alpha3Code"
            :value="option.webRiposteCode"
        >
            {{ option.name }}
        </option>
        </select>
        <p class="error" v-if="originCountryValid === false">You must select a country of origin for your item(s)</p>
        <label for="hsTariff" class="form-label --info">HS Tariff code  <b class="form-label.required">*</b><a href="https://www.gov.uk/trade-tariff" target="_blank"><span class="form-label.info"></span></a></label>
        <input 
            type="text" 
            name="hsTariff" 
            id="hsTariff" 
            class="form-text-field" 
            placeholder="Please enter the HS tariff code" 
            v-model="mainstore.selected.customsItems[itemIndex].itemLines.TARIFFCODE"
            @blur="handleFormatHsTariff()"
            maxlength="13"
        />
        <p class="form-field-message">Please find the HS Tariff code for your item on the <a href="https://www.gov.uk/trade-tariff" target="_blank">gov.uk HS Tariff Lookup</a></p>
        <p class="error" v-if="hsTariffValid === false">HS Tariff Code MUST be 13 numeric characters or less</p>

        <label for="itemQuantity" class="form-label">Quantity<b class="form-label.required">*</b></label>
        <input 
            type="number" 
            name="itemQuantity" 
            id="itemQuantity" 
            class="form-number-field" 
            placeholder="0" 
            v-model="mainstore.selected.customsItems[itemIndex].itemLines.ITEMQUANTITY"
            @change="validateItemQuantity()"
            @blur="handleFormatQuantity()"
        />
        <p class="error" v-if="itemQuantityValid === false">You must enter a quantity</p>

        <label for="itemWeight" class="form-label">Item weight ({{ selectedUnit }})<b class="form-label.required">*</b></label>
        <div class="weight-input-group">
            <input 
                type="number" 
                name="itemWeight" 
                id="itemWeight" 
                class="form-number-field" 
                placeholder="0" 
                v-model.number="weightInput"
                @input="handleWeightChange"
                @blur="handleFormatWeight"
                step="1"
            />
            <select class="weight-input-group.options" v-model="selectedUnit" @change="handleUnitChange">
                <option value="kg">Kg</option>
                <option value="g">Grams</option>
            </select>
        </div>
        <p class="error" v-if="itemWeightValid === false">You must enter a weight greater than 0</p>

        <label for="itemValue" class="form-label">Item value (£)<b class="form-label.required">*</b></label>
        <input 
            type="number" 
            name="itemValue" 
            id="itemValue" 
            class="form-number-field" 
            placeholder="0" 
            v-model="mainstore.selected.customsItems[itemIndex].itemLines.ITEMVALUE"
            @change="validateItemValue(), addValues()"
            @blur="handleFormatValue()"
            step="1"
        />
        <p class="error" v-if="itemValueValid === false">You must enter a value in Pounds(£) for your item</p>

        <button class="customs-item.clear-button" v-if="this.firstItem === true" @click="clearItem">Clear</button>
        <button class="customs-item.clear-button" v-else @click="deleteItem">Delete item</button>
    </fieldset>
</template>

<script>
import { useMainStore } from '@/stores/MainStore.ts';
import axios from 'axios';

export default {
    name: 'CustomsItem',

    emits: [
        'delete-item',
        'add-values',
        'clear-item'
    ],

    props: {
        firstItem: Boolean,
        itemIndex: Number
    },

    setup() {
        const mainstore = useMainStore();
        return {
            mainstore
        };
    },

    data() {
        return {
            countries: [],
            itemDescriptionValid: null,
            originCountryValid: null,
            hsTariffValid: null,
            itemQuantityValid: null,
            itemWeightValid: null,
            itemValueValid: null,
            selectedUnit: 'kg',
            weightInput: 0
        };
    },

    mounted() {
        axios({
            method: 'get',
            url: process.env.VUE_APP_API_COUNTRIES,
            headers: {
                'Authorization': process.env.VUE_APP_AUTHORIZATION,
            }
        })
        .then(response => (this.countries = response.data.countryList))
        .catch(err => console.error(err));

        // Initialize weightInput based on store value
        const weightInKg = parseFloat(this.mainstore.selected.customsItems[this.itemIndex]?.itemLines?.NETWEIGHT || 0);
        this.weightInput = this.selectedUnit === 'kg' ? weightInKg : (weightInKg * 1000).toFixed(2);
    },

    methods: {
        deleteItem() {
            this.$emit('delete-item');
        },

        addValues() {
            this.$emit('add-values');
        },

        clearItem() {
            const itemLines = this.mainstore.selected.customsItems[this.itemIndex].itemLines;
            itemLines.ITEMDESCRIPTION = '';
            itemLines.COUNTRYOFORIGIN = '';
            itemLines.TARIFFCODE = '';
            itemLines.ITEMQUANTITY = null;
            itemLines.NETWEIGHT = null;
            itemLines.ITEMVALUE = null;

            this.$emit('clear-item');
        },

        validateItemDescription() {
            const data = this.mainstore.selected.customsItems[this.itemIndex]?.itemLines?.ITEMDESCRIPTION || '';
            this.itemDescriptionValid = data.length >= 4 && data.length <= 30;
        },
        validateOriginCountry() {
            const data = this.mainstore.selected.customsItems[this.itemIndex]?.itemLines?.COUNTRYOFORIGIN || '';
            this.originCountryValid = data !== '';
        },
        validateItemQuantity() {
            const data = this.mainstore.selected.customsItems[this.itemIndex]?.itemLines?.ITEMQUANTITY;
            this.itemQuantityValid = data !== null && parseFloat(data) !== 0;
        },
        validateItemWeight() {
            const data = this.mainstore.selected.customsItems[this.itemIndex]?.itemLines?.NETWEIGHT;
            this.itemWeightValid = data !== null && parseFloat(data) > 0;
        },
        validateItemValue() {
            const data = this.mainstore.selected.customsItems[this.itemIndex]?.itemLines?.ITEMVALUE;
            this.itemValueValid = data !== null;
        },
        validateHsTariff() {
            const data = this.mainstore.selected.customsItems[this.itemIndex]?.itemLines?.TARIFFCODE || '';
            const regex = /^[0-9]{10,13}$/g;
            this.hsTariffValid = regex.test(data);
        },
        handleFormatHsTariff() {
            let data = this.mainstore.selected.customsItems[this.itemIndex]?.itemLines?.TARIFFCODE || '';
            data = data.trim();
            if (data.length < 10) {
                const formatRemainder = 10 - data.length;
                for (let i = 0; i < formatRemainder; i++) {
                    data = data.concat("0");
                }
            }
            this.mainstore.selected.customsItems[this.itemIndex].itemLines.TARIFFCODE = data;
            this.validateHsTariff();
        },
        handleFormatQuantity() {
            let quantity = parseFloat(this.mainstore.selected.customsItems[this.itemIndex].itemLines.ITEMQUANTITY)
            this.mainstore.selected.customsItems[this.itemIndex].itemLines.ITEMQUANTITY = quantity.toFixed(0);
        },
        handleWeightChange() {
            let weight = parseFloat(this.weightInput);
            if (this.selectedUnit === 'g') {
                weight = weight / 1000; // Convert grams to kilograms
            }
            this.mainstore.selected.customsItems[this.itemIndex].itemLines.NETWEIGHT = weight;
            this.validateItemWeight();
        },
        handleFormatWeight() {
            let weight = parseFloat(this.weightInput);
            if (this.selectedUnit === 'kg') {
                this.weightInput = weight.toFixed(3);
            } else {
                this.weightInput = weight.toFixed(0);
            }
        },
        handleUnitChange() {
            let weight = parseFloat(this.mainstore.selected.customsItems[this.itemIndex]?.itemLines?.NETWEIGHT || 0);
            if (this.selectedUnit === 'kg') {
                this.weightInput = weight.toFixed(3);
            } else {
                this.weightInput = (weight * 1000).toFixed(0);
            }
        },
        handleFormatValue() {
            let value = parseFloat(this.mainstore.selected.customsItems[this.itemIndex].itemLines.ITEMVALUE);
            this.mainstore.selected.customsItems[this.itemIndex].itemLines.ITEMVALUE = value.toFixed(2);
        },
    },

    watch: {
        'mainstore.selected.customsItems[itemIndex].itemLines.NETWEIGHT'(newWeight) {
            if (this.selectedUnit === 'g') {
                this.weightInput = (newWeight * 1000); // Update input field when NETWEIGHT changes
            } else {
                this.weightInput = newWeight;
            }
        }
    }
};
</script>

<style scoped>
.weight-input-group {
    display: flex;
    align-items: center;
}
.weight-input-group select {
    margin-left: 10px;
}
</style>