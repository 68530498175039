<template>
    <div class="password-reset">
        <div class="content">
            <form class="password-reset.form">
                <h2 class="password-reset.form.title">Set your new password</h2>
                <p class="password-reset.form.intro">Your password must contain at least one uppercase letter, at least one number, at least one special character and it must be eight characters or more in length.</p>
                <label for="resetPassword" class="form-label">New password</label>
                <input class="form-text-field" id="resetPassword" v-model="password" type="password" placeholder="Password" required @blur="validatePassword"/>
                <label for="resetPasswordConfirmation" class="form-label">Confirm new password</label>
                <input class="form-text-field" id="resetPasswordConfirmation" v-model="confirmPassword" type="password" placeholder="Confirm password" required @input="validatePassword"/>
            </form>
            <p v-if="validationErrors.length > 0" class="error">{{ validationErrors.join(' ') }}</p>
            <p v-if="validationErrors.length === 0 && valid === false" class="error">Passwords do not match</p>
            <MainButton btntxt="Confirm password change" :class="{'--disabled': !valid || validationErrors.length > 0}" @click="handleSubmit"/>
        </div>
    </div>
</template>

<script>
import MainButton from './MainButton.vue';
import axios from 'axios';

export default {
    name: 'PasswordReset',
    components: {
        MainButton
    },

    data() {
        return {
            password: '',
            confirmPassword: '',
            queryParameter: null,
            valid: null,
            validationErrors: []
        }
    },

    mounted() {
        if (this.$route.query.confirmation) {
            this.queryParameter = this.$route.query.confirmation;
        }
        console.log(this.queryParameter);
    },

    methods: {
        validatePassword() {
            this.validationErrors = []; // Clear previous errors

            // Check for uppercase letter
            if (!/[A-Z]/.test(this.password)) {
                this.validationErrors.push('Password must contain at least one uppercase letter.');
            }

            // Check for lowercase letter
            if (!/[a-z]/.test(this.password)) {
                this.validationErrors.push('Password must contain at least one lowercase letter.');
            }

            // Check for number
            if (!/[0-9]/.test(this.password)) {
                this.validationErrors.push('Password must contain at least one number.');
            }

            // Check for special character
            if (!/[!@#$%^&*(),.?":{}|<>]/.test(this.password)) {
                this.validationErrors.push('Password must contain at least one special character.');
            }

            // Check for minimum length
            if (this.password.length < 8) {
                this.validationErrors.push('Password must be at least 8 characters long.');
            }

            // Check if passwords match
            if (this.password !== this.confirmPassword) {
                this.valid = false;
            } else {
                this.valid = this.validationErrors.length === 0; // Set valid based on validationErrors
            }
        },

        handleSubmit() {
            axios.post(process.env.VUE_APP_API_RESET_PASSWORD, {
                code: this.queryParameter,
                password: this.password,
                passwordConfirmation: this.confirmPassword,
            })
            .then(response => {
                alert('Password reset successful:', response.data);
            })
            .catch(error => {
                alert('Error resetting password:', error.response?.data);
            });
        }
    }
}
</script>