<template>
    <div class="collection-item">
        <div class="collection-item.title">
            <h2>Collection</h2>
        </div>
        <div class="collection-item.content">
            <div class="collection-item.content.section">
                <span class="collection-item.content.section.intro">
                    You have added collection to your basket. Your items will be collected from:
                </span>
                <div> 
                
                    <span class="collection-item.content.section.address" v-if="collectionstore.collectionData.collectionAddress.ADDRESSLINE1">{{ capitalizeWords(collectionstore.collectionData.collectionAddress.ADDRESSLINE1) }}</span>
                    <span class="collection-item.content.section.address" v-if="collectionstore.collectionData.collectionAddress.ADDRESSLINE2">{{ capitalizeWords(collectionstore.collectionData.collectionAddress.ADDRESSLINE2) }}</span>
                    <span class="collection-item.content.section.address" v-if="collectionstore.collectionData.collectionAddress.ADDRESSLINE3">{{ capitalizeWords(collectionstore.collectionData.collectionAddress.ADDRESSLINE3) }}</span>
                    <span class="collection-item.content.section.address">{{ capitalizeWords(collectionstore.collectionData.collectionAddress.CITY) }}</span>
                    <span class="collection-item.content.section.address">{{ collectionstore.collectionData.collectionAddress.POSTCODE }}</span>
                
                </div>
            </div>
            <div class="collection-item.content.section">
                <span class="collection-item.content.section.intro">
                    Collection will be made on:
                </span>
                <span class="collection-item.content.section.date"> {{ convertDate(collectionstore.collectionData.collectionDate) }}</span>
            </div>
        </div>
        <div class="collection-item.price" v-if="this.mainstore.collectionPrice !== 0">
            <span class="collection-item.price.value">£{{ this.mainstore.collectionPricePounds }}</span>
            <span class="collection-item.price.vat"> incl. VAT</span>
        </div>

    </div>
</template>

<script>
import { useCollectionStore } from "@/stores/CollectionStore.ts";
import { useMainStore } from "@/stores/MainStore.ts";

    export default {
        name: "CollectionItem",

        setup() {
            const collectionstore = useCollectionStore();
            const mainstore = useMainStore();
            return { collectionstore, mainstore };
        },

        methods: {
            capitalizeWords(str) {
                let words = str.toLowerCase().split(" ");
                for (let i = 0; i < words.length; i++) {
                    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
                }
                return words.join(" ");
            },

            convertDate(data) {
                const date = new Date(data);
                const options = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                };
                const ukDate = date.toLocaleDateString('en-GB', options);
                return ukDate;
            }
        }
    }

</script>