<template>
    <div class="contacts-select">
        <span class="contacts-select.close" @click="handleClose"></span>
        <h2 class="contacts-select.title">Select a contact</h2>
        <label for="search" class="form-label">Search</label>
        <input 
            id="search"
            class="form-text-field contacts-select.search"
            name="Search"
            v-model="searchQuery" 
            placeholder="Search contacts..." 
        />

        <div 
            v-for="contact in filteredContacts" 
            :key="contact.id" 
            class="contacts-select.contact" 
            ref="contactRefs" @click="useAddress(contact)" 
            v-show="contact.country === mainstore.selected.destination.alpha3Code || contact.country === mainstore.selected.destination.alpha2Code || contact.country === mainstore.selected.destination.webRiposteCode"
        >
            <span class="contact-select.contact.name" v-if="contact.FIRSTNAME && contact.LASTNAME">{{ capitalizeWords((contact.FIRSTNAME || '') + ' ' + (contact.LASTNAME || '')) }}</span>
            <div class="contacts-select.contact.address">
                <span v-if="contact.ORG" class="contacts-select.contact.address.line">{{ capitalizeWords(contact.ORG) }}</span>
                <span v-if="contact.ADDRESSLINE1" class="contacts-select.contact.address.line">{{ capitalizeWords(contact.ADDRESSLINE1) }}</span>
                <span v-if="contact.ADDRESSLINE2" class="contacts-select.contact.address.line">{{ capitalizeWords(contact.ADDRESSLINE2) }}</span>
                <span v-if="contact.ADDRESSLINE3" class="contacts-select.contact.address.line">{{ capitalizeWords(contact.ADDRESSLINE3) }}</span>
                <span v-if="contact.CITY" class="contacts-select.contact.address.line">{{ capitalizeWords(contact.CITY) }}</span>
                <span v-if="contact.COUNTY" class="contacts-select.contact.address.line">{{ capitalizeWords(contact.COUNTY) }}</span>
                <span v-if="contact.countryName" class="contacts-select.contact.address.line">{{ capitalizeWords(contact.countryName) }}</span>
                <span v-if="contact.POSTCODE" class="contacts-select.contact.address.line">{{ contact.POSTCODE }}</span>
            </div>
            <span class="contacts-select.contact.telephone" v-if="contact.TELEPHONE">{{ contact.TELEPHONE }}</span>
        </div>

    </div>
</template>

<script>
import { useMainStore } from '@/stores/MainStore.ts';
import anime from 'animejs';

export default {
    name: 'ContactsSelect',

    data() {
        return {
            searchQuery: ''
        }
    },

    setup() {
        const mainstore = useMainStore();

        return {
            mainstore
        };
    },

    computed: {
        filteredContacts() {
            const query = this.searchQuery.toLowerCase();

            const filtered = this.addresses.filter(contact => {
                return (
                    (contact.ORG && contact.ORG.toLowerCase().includes(query)) ||
                    (contact.FIRSTNAME && contact.FIRSTNAME.toLowerCase().includes(query)) ||
                    (contact.LASTNAME && contact.LASTNAME.toLowerCase().includes(query)) ||
                    (contact.ADDRESSLINE1 && contact.ADDRESSLINE1.toLowerCase().includes(query)) ||
                    (contact.ADDRESSLINE2 && contact.ADDRESSLINE2.toLowerCase().includes(query)) ||
                    (contact.ADDRESSLINE3 && contact.ADDRESSLINE3.toLowerCase().includes(query)) ||
                    (contact.CITY && contact.CITY.toLowerCase().includes(query)) ||
                    (contact.POSTCODE && contact.POSTCODE.toLowerCase().includes(query)) ||
                    (contact.COUNTY && contact.COUNTY.toLowerCase().includes(query)) ||
                    (contact.TELEPHONE && contact.TELEPHONE.toLowerCase().includes(query))
                );
            });

            return filtered.sort((a, b) => {
                const lastnameA = a.LASTNAME ? a.LASTNAME.toLowerCase() : '';
                const lastnameB = b.LASTNAME ? b.LASTNAME.toLowerCase() : '';
                if (lastnameA < lastnameB) return -1;
                if (lastnameA > lastnameB) return 1;
                return 0;
            });
        },

        addresses() {
            return this.mainstore.user.user.addressBook 
                ? Object.values(this.mainstore.user.user.addressBook) 
                : [];
        }
    },

    methods: {

        animateContacts() {
            anime({
                targets: this.$refs.contactRefs,
                opacity: [0, 1],
                translateX: [50, 0],
                delay: anime.stagger(100),
                easing: 'easeOutQuad',
                duration: 500
            });
        },

        animate(){
            const sidebar = document.querySelector('.contacts-select');
            const sidebarWidth = sidebar.offsetWidth;
            anime({
                targets: '.contacts-select',
                translateX: [sidebarWidth, 0],
                easing: 'easeOutQuad',
                duration: 200
            });
        },

        capitalizeWords(str) {
            let words = str.toLowerCase().split(" ");
            for (let i = 0; i < words.length; i++) {
                words[i] = words[i][0].toUpperCase() + words[i].slice(1);
            }
            return words.join(" ");
        },

        handleClose() {
            this.$emit('close');
        },

        useAddress(contact) {
            this.mainstore.selected.recipientDetails = {
                firstName: contact.FIRSTNAME || "",
                lastName: contact.LASTNAME || "",
                telephone: contact.TELEPHONE || "",
                address: {
                    RECIPIENTORG: contact.ORG || "",
                    RECIPIENTCOMPANY: contact.COMPANY || "",
                    RECIPIENTADDRESSLINE1: contact.ADDRESSLINE1 || "",
                    RECIPIENTADDRESSLINE2: contact.ADDRESSLINE2 || "",
                    RECIPIENTADDRESSLINE3: contact.ADDRESSLINE3 || "",
                    RECIPIENTCITY: contact.CITY || "",
                    RECIPIENTCOUNTY: contact.COUNTY || "",
                    RECIPIENTPOSTCODE: contact.POSTCODE || "",
                    RECIPIENTCOUNTRY: contact.country || ""
                }
            };

            this.mainstore.selected.usedContact = true;

            this.mainstore.validations.recipientDetailsValid = true;

            this.handleClose();
        }


    },

    mounted() {
        this.animate();
        //this.animateContacts();
    }
}
</script>