<template>
    <div class="login-modal">
        <div class="login-modal.content" v-if="!passwordReset">
            <span class="login-modal.content.close" @click="handleClick"></span>
            <div class="login-modal.content.tabs">
                <a href="#" class="login-modal.content.tabs.tab" :class="{ active: !isSignup, '--active': !isSignup }"
                    @click.prevent="setTab('signin')">Sign in</a>
                <a href="#" class="login-modal.content.tabs.tab" :class="{ active: isSignup, '--active': isSignup }"
                    @click.prevent="setTab('signup')">Sign up</a>
            </div>
            <div class="login-modal.content.form">
                <h2 class="login-modal.content.form.title">{{ buttonText }}</h2>
                <span class="login-modal.content.form.required-text">Fields marked with <b class="form-label.required">*</b> are required</span>
                <p v-if="!isSignup && this.emailConfirmed" class="login-modal.content.form.text">Thank you for confirming your email address.<br /> You can now sign into your account</p>
                <form @submit.prevent="handleSubmit">
                    <label for="firstName" class="form-label" v-if="isSignup">First Name<b class="form-label.required">*</b></label>
                    <input id="firstName" class="form-text-field" v-model="formData.firstname" type="text" placeholder="First name" required v-if="isSignup" @input="validateFirstName" maxlength="15"/>
                    <p v-if="firstNameValid === false" class="error --sign-up --centered">Your first name must be between 3 and 15 characters</p>
                    <label for="lastName" class="form-label" v-if="isSignup">Last Name<b class="form-label.required">*</b></label>
                    <input id="lastName" class="form-text-field" v-model="formData.lastname" type="text" placeholder="Last name" required v-if="isSignup" @input="validateLastName" maxlength="15"/>
                    <p v-if="lastNameValid === false" class="error --sign-up --centered">Your last name must be between 3 and 15 characters</p>
                    <label for="email" class="form-label" v-if="isSignup">Email address<b class="form-label.required">*</b></label>
                    <label for="email" class="form-label" v-else>Username / Email address<b class="form-label.required">*</b></label>
                    <input id="email" class="form-text-field" v-model="formData.email" type="email" placeholder="Email" required @input="validateEmail"/>
                    <p v-if="emailValid === false && isSignup" class="error --sign-up --centered">Invalid email address</p>
                    <label for="contactTelephone" class="form-label" v-if="isSignup">Telephone number<b class="form-label.required">*</b></label>
                    <input id="contactNumber" class="form-text-field" v-model="formData.contactNumber" type="text" placeholder="Telephone number" required v-if="isSignup" @input="validateContactTelephone"/>
                    <p v-if="contactTelephoneValid === false" class="error --sign-up --centered">Invalid telephone number</p>
                    <label for="password" class="form-label">Password<b class="form-label.required">*</b></label>
                    <input id="password" class="form-text-field" v-model="formData.password" type="password" placeholder="Password" required @input="validatePassword"/>
                    <p v-if="validationErrors.length > 0 && isSignup" class="error">{{ validationErrors.join(' ') }}</p>
                    <label for="confirmPassword" class="form-label" v-if="isSignup">Confirm password<b class="form-label.required">*</b></label>
                    <input id="confirmPassword" class="form-text-field" v-model="confirmedPassword" type="password" placeholder="Confirm password" required v-if="isSignup" @input="checkPasswordMatch"/>
                    <p v-if="passwordMatchValid === false" class="error --sign-up --centered">Passwords do not match</p>
                    <!--<TypeaheadAddress v-if="isSignup"/>-->
                    <MainButton :btntxt="buttonText" :isLoading="buttonLoading" :class="{'--disabled': isButtonDisabled}"/>
                    <a href="#" v-if="!isSignup" class="login-modal.content.form.password-reset" @click="passwordReset = true">Forgot password?</a>
                </form>
                <div v-if="submitted" class="login-modal.content.form.email-sent">
                    <h3 class="login-modal.content.form.email-sent.title">Thank you for signing up for an Isle of Man Post Office Account.</h3>
                    <p class="login-modal.content.form.email-sent.text">We've sent a verification email with a link. Please click it to activate your account. Your account will not be available until verified.</p>
                </div>
                <p v-if="errorMessage" class="error --sign-up --centered">{{ errorMessage }}</p>
            </div>
        </div>
        <div class="login-modal.content" v-else>
            <div class="login-modal-padding">
                <span class="login-modal.content.close" @click="handleClick"></span>
                <h2 class="login-modal.content.reset-title">Please enter your email address.</h2>
                <p class="login-modal.content.text">We will send you an email with instructions on how to reset your password</p>
                <label for="email" class="form-label">Username / Email address</label>
                <input id="email" class="form-text-field" v-model="formData.email" type="email" placeholder="Email" required />
                <p v-if="resetData?.data.ok" class="login-modal.content.text">Email address submitted. Check your email</p>
                <MainButton btntxt="Submit" :isLoading="buttonLoading" :class="{'--disabled': !formData.email || resetData?.data.ok}" @click="handlePasswordReset()"/>
            </div>
        </div>
    </div>
</template>


<script>
import MainButton from '@/components/MainButton';
import { useMainStore } from "@/stores/MainStore.ts";
import axios from 'axios';

export default {
    name: 'LoginModal',

    components: {
        MainButton
    },

    data() {
        return {
            isSignup: false,
            formData: {
                firstname: '',
                lastname: '',
                email: '',
                contactNumber: '',
                password: ''
            },
            confirmedPassword: '',
            errorMessage: '',
            confirm: false,
            firstNameValid: null,
            lastNameValid: null,
            passwordMatchValid: null,
            emailValid: null,
            contactTelephoneValid: null,
            passwordReset: false,
            resetData: null,
            validationErrors: [],
            buttonLoading: false,
            submitted: false
        };
    },

    computed: {
        mainstore() {
            return useMainStore();
        },
        buttonText() {
            return this.isSignup ? 'Sign up' : 'Sign in';
        },
        endpoint() {
            if (this.isSignup) {
                return process.env.VUE_APP_API_REGISTER;
            } else {
                return process.env.VUE_APP_API_SIGNIN;
            }
        },
        errorText() {
            return this.isSignup ? 'sign up' : 'sign in';
        },

        payload() {
            if (this.isSignup) {
                return {
                    "username": this.formData.email,
                    "firstName": this.formData.firstname,
                    "lastName": this.formData.lastname,
                    "email": this.formData.email,
                    "contactNumber": this.formData.contactNumber,
                    "password": this.formData.password
                }
            } else {
                return {
                    "identifier": this.formData.email,
                    "password": this.formData.password
                }
            }
        },

        isButtonDisabled() {
            if (this.isSignup) {
                // During sign-up, validate all required fields
                return (
                    !this.firstNameValid ||
                    !this.lastNameValid ||
                    !this.passwordMatchValid ||
                    !this.emailValid ||
                    !this.contactTelephoneValid ||
                    this.validationErrors.length > 0
                );
            } else {
                // During sign-in, validate only email and password
                return !this.emailValid || this.formData.password.length === 0;
            }
        }
    },

    methods: {
        setTab(tab) {
            this.isSignup = (tab === 'signup');
            this.formData = {
                firstname: '',
                lastname: '',
                email: '',
                contactNumber: '',
                password: '',
            };
            this.confirmedPassword = '';
            this.errorMessage = '';
            this.firstNameValid = null;
            this.lastNameValid = null;
            this.emailValid = null;
            this.contactTelephoneValid = null;
            this.passwordMatchValid = null;
            this.validationErrors = [];
        },

        handleClick() {
            this.mainstore.loginModal = false;
            this.mainstore.modalOpen = false;
        },

        async handleSubmit() {
            let payload = this.payload;
            this.buttonLoading = true;

            try {
                const response = await axios.post(this.endpoint, payload);

                // Check if the user is not confirmed
                if (response.data?.user?.confirmed === false) {
                    this.submitted = true; // sign-up was successful
                    return;
                }

                // Proceed with login if confirmed
                this.mainstore.user = response.data;
                this.mainstore.user.user.country = "IMN";
                this.mainstore.loginModal = false;
                this.mainstore.modalOpen = false;
                this.confirm = true;

                // Redirect to home if the route is accountCreated
                if (this.$route.name === "accountCreated") {
                    this.$router.push("/");
                }
            } catch (error) {
                this.errorMessage = error.response?.data?.error?.message 
                    ? error.response.data.error.message 
                    : `Failed to ${this.errorText}, please try again.`;
            } finally {
                this.buttonLoading = false;
            }
        },

        validateFirstName() {
            let data = this.formData.firstname;
            if(data.length < 3) {
                this.firstNameValid = false;
            } else {
                this.firstNameValid = true
            }
        },

        validateLastName() {
            let data = this.formData.lastname;
            if(data.length < 3) {
                this.lastNameValid = false;
            } else {
                this.lastNameValid = true
            }
        },

        validatePassword() {
            this.validationErrors = []; // Clear previous errors

            // Check for uppercase letter
            if (!/[A-Z]/.test(this.formData.password)) {
                this.validationErrors.push('Password must contain at least one uppercase letter.');
            }

            // Check for lowercase letter
            if (!/[a-z]/.test(this.formData.password)) {
                this.validationErrors.push('Password must contain at least one lowercase letter.');
            }

            // Check for number
            if (!/[0-9]/.test(this.formData.password)) {
                this.validationErrors.push('Password must contain at least one number.');
            }

            // Check for special character
            if (!/[!@#$%^&*(),.?":{}|<>]/.test(this.formData.password)) {
                this.validationErrors.push('Password must contain at least one special character.');
            }

            // Check for minimum length
            if (this.formData.password.length < 8) {
                this.validationErrors.push('Password must be at least 8 characters long.');
            }
        },

        validateEmail() {
            let data = this.formData.email;
            // eslint-disable-next-line
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data)) {
                this.emailValid = true;
            } else {
                this.emailValid = false;
            }
        },

        validateContactTelephone() {
            let data = this.formData.contactNumber;
            // eslint-disable-next-line
            if(/^[\(\)\+ 0-9]{10,20}$/.test(data)) {
                this.contactTelephoneValid = true;
            } else {
                this.contactTelephoneValid = false;
            }
        },

        checkPasswordMatch() {
            const password = this.formData.password;
            const confirm = this.confirmedPassword;
            let matching = null;
            if (password == confirm) {
                matching = true;
            } else {
                matching = false; 
            }
            this.passwordMatchValid = matching;
        },

        async handlePasswordReset() {
            this.buttonLoading = true;
            const payload = { email: this.formData.email };

            try {
                const response = await axios.post(
                    process.env.VUE_APP_API_FORGOTPASSWORD,
                    payload,
                    { headers: { "Content-Type": "application/json" } }
                );
                this.resetData = response;
            } catch (error) {
                console.error(error.response?.data || error.message);
                this.errorMessage = `Failed to reset password, please try again.`;
            } finally {
                this.buttonLoading = false;
            }
        }
    },

    created() {
        if (this.emailConfirmed) {
            this.isSignup = false;
        }
    }
};
</script>

<style scoped>
    .form-label {
        text-align: left;
    }

    .login-modal-padding {
        padding: 30px;
    }
</style>