<template>
  <SkipLinks />

  <SiteNavigation @accountClicked="openAccountDetails" />

  <form @submit.prevent="onSubmit" autocomplete="off">
    <router-view></router-view>
  </form>

  <MarketingMessage v-show="this.$route.name == 'home'" />

  <PostageProcess v-show="this.$route.name == 'home'" />

  <PostageOptions v-show="this.$route.name == 'home'" />

  <FrequentQuestions />

  <SiteFooter />

  <SystemDowntime @accepted="closeModal" />

  <CookiePrompt @accepted="closeModal" />

  <InterstitialLoader v-if="mainstore.pageLoading === true" />

  <LoginModal v-if="mainstore.loginModal" />

  <AccountDetails v-if="this.accountOpen && mainstore.user?.user" @accountClosed="closeAccount" @deleteAccount="deleteAccount" @open="contactManagerOpen = true"/>

  <ContactManager v-if="contactManagerOpen" @close="contactManagerOpen = false"/>

  <ContactsSelect v-if="mainstore.contactsOpen" @close="mainstore.contactsOpen = false"/>

  <DeleteModal v-if="showConfirmation" @confirmationClosed="showConfirmation = false"
    @confirmedDeleteAccount="confirmedDeleteAccount" />
  
  <ToastNotification ref="toast"/>
</template>

<script>
import SkipLinks from "./components/SkipLinks.vue";
import SiteNavigation from "./components/SiteNavigation.vue";
import PostageProcess from "./components/PostageProcess.vue";
import PostageOptions from "./components/PostageOptions.vue";
import FrequentQuestions from "./components/FrequentQuestions.vue";
import SiteFooter from "./components/SiteFooter.vue";
import SystemDowntime from "./components/SystemDowntime.vue";
import CookiePrompt from "./components/CookiePrompt.vue";
import InterstitialLoader from "./components/InterstitialLoader.vue";
import { useMainStore } from "@/stores/MainStore.ts";
import LoginModal from "./components/LoginModal.vue";
import { computed } from "vue";
import AccountDetails from "./components/AccountDetails.vue";
import ContactManager from "./components/ContactManager.vue";
import ContactsSelect from "./components/ContactsSelect.vue";
import MarketingMessage from "./components/MarketingMessage.vue";
import DeleteModal from "./components/DeleteModal.vue";
import axios from "axios";
import router from "./router";
import ToastNotification from "./components/ToastNotification.vue";

export default {
  setup() {
    const mainstore = useMainStore();

    const popoverActive = computed({
      get: () => mainstore.modalOpen,

      set: (value) => {
        mainstore.modalOpen = value;
      }
    });

    router.afterEach((to) => {
      if (to.path !== "/") {
        try {
          /* global fbq */
          fbq("track", "ViewContent");
        } catch {
          console.log("Analytics disabled")
        }
      }
    });

    return {
      mainstore,
      popoverActive
    };
  },

  components: {
    SiteNavigation,
    SkipLinks,
    FrequentQuestions,
    SiteFooter,
    PostageProcess,
    PostageOptions,
    SystemDowntime,
    CookiePrompt,
    InterstitialLoader,
    LoginModal,
    AccountDetails,
    ContactManager,
    ContactsSelect,
    DeleteModal,
    MarketingMessage,
    ToastNotification
  },

  data() {
    return {
      accountOpen: false,
      contactManagerOpen: false,
      showConfirmation: false,
      queryParameter: null
    };
  },

  watch: {
    popoverActive(value) {
      if (value) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },

    "$route.query.emailConfirmation"(newValue) {
      if (newValue) {
        this.queryParameter = newValue;
        this.mainstore.loginModal = true;
      }
    }
  },

  methods: {
    closeModal() {
      this.popoverActive = false;
    },

    openAccountDetails() {
      this.accountOpen = true;
    },

    closeAccount() {
      this.accountOpen = false;
    },

    deleteAccount() {
      this.showConfirmation = true;
    },

    getUserData() {
      const jwt = this.mainstore.jwt;
      const getDataUrl = process.env.VUE_APP_API_UPDATE;

      axios.get(getDataUrl, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
      .then(response => {
        console.log("Updated user data:", response.data);
        this.mainstore.user.user = response.data;
      })
      .catch(error => {
        console.error("Error fetching user data:", error.response);
      });
    },


    confirmedDeleteAccount() {
      const jwt = this.mainstore.user.jwt;
      const deleteApiUrl = process.env.VUE_APP_API_DELETE;

      axios
        .delete(deleteApiUrl, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })

        .then(() => {

          this.$refs.toast.showToast("Account successfully deleted!");
          //alert("Successfully deleted account");

          this.mainstore.user = null;

          window.resetLocalStorage = () => {
            localStorage.removeItem("user");
          };

          this.showConfirmation = false;
        })

        .catch((error) => {
          console.error(
            "Error deleting account:",
            error.response?.data || error.message
          );

          alert(
            "There was an issue deleting your account. Please try again later."
          );
        });
    }
  },

  created() {
    /*
    this.$nextTick(() => {
      if (this.$route.query.emailConfirmation) {
        this.queryParameter = this.$route.query.emailConfirmation;

        this.mainstore.loginModal = true;
      }
    });
    */

    this.$nextTick(() => {
      if (this.$route.query.confirmation) {
        this.queryParameter = this.$route.query.confirmation;

        this.mainstore.loginModal = true;
      }
    });

    //this.getUserData();
  }
};
</script>
