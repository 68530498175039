<template>
    <div v-if="visible" class="toast-notification">
        {{ message }}
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            message: "",
        };
    },
    methods: {
        showToast(msg) {
            this.message = msg;
            this.visible = true;
            setTimeout(() => {
                this.visible = false;
            }, 3000);
        },
    },
};
</script>