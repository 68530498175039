<template>
    <fieldset class="package-card-container">
        <div v-for="item in products" :key="item.id" class="package-card-container.item">
            <label class="package-card" :class="{'--selected': (mainstore.selected.parcelSize.id === item.id)}" v-if="item.maxWeight >= mainstore.selected.weight && !(item.restrictions.includes('IM') && mainstore.selected.destination.webRiposteCode === 'IM')">
                <input type="radio" required name="parcelsize" v-model="mainstore.selected.parcelSize" :value="item" @change="onParcelSizeChange">
                <h2 class="package-card.title">{{ item.displayName }}</h2>
                <img :src="require('@/assets/img/'+ item.image)" class="package-card.image"/>
                <span class="package-card.details">
                    <span class="package-card.details.weight" v-if="item.maxWeight < 1000">Up to: <b>{{ item.maxWeight }}g</b></span>
                    <span class="package-card.details.weight" v-else >Up to: <b>{{ parcelMaxWeight }}kg</b></span>
                    <span class="package-card.details.size">Max-size: <b>{{ item.size }}</b></span>
                    <span class="package-card.details.info" v-if="showInfoLink(item)"><a :href="item.info + '/' + mainstore.selected.destination.name" target="_blank">Restrictions for international parcel sizes. Please check.</a></span>
                </span>
                <!--Need lowest price per item per country to display accurate pricing-->
                <!--<span class="package-card.price"><span class="package-card.price.price-from">From:</span>£{{ item.price }}</span>-->
                <span class="package-card.checkmark"></span>
            </label>
            <label class="package-card --inactive" :class="{'--selected': (mainstore.selected.parcelSize.id === item.id)}" v-else>
                <input type="radio" required name="parcelsize" v-model="mainstore.selected.parcelSize" :value="item" @change="onParcelSizeChange">
                <h2 class="package-card.title">{{ item.displayName }}</h2>
                <img :src="require('@/assets/img/'+ item.image)" class="package-card.image"/>
                <span class="package-card.details">
                    <span class="package-card.details.weight" v-if="item.maxWeight < 1000">Up to: <b>{{ item.maxWeight }}g</b></span>
                    <!--<span class="package-card.details.weight" v-else >Up to: <b>{{ item.maxWeight/1000 }}kg</b></span>-->
                    <span class="package-card.details.weight" v-else >Up to: <b>{{ parcelMaxWeight/1000 }}kg</b></span>
                    <span class="package-card.details.size">Max-size: <b>{{ item.size }}</b></span>
                    <span class="package-card.details.info" v-if="showInfoLink(item)"><a :href="item.info + '/' + mainstore.selected.destination.name" target="_blank">Restrictions for international parcel sizes. Please check.</a></span>
                </span>
                <!--Need lowest price per item per country to display accurate pricing-->
                <!--<span class="package-card.price"><span class="package-card.price.price-from">From:</span>£{{ item.price }}</span>-->
                <span class="package-card.checkmark"></span>
            </label>
            
        </div>
    </fieldset>
    <div v-show="this.mainstore.validations.parcelSizeValid == false" class="message">
        <span class="message.text">Please select a size above to display available services here</span>
    </div>
    <AvailableServices v-show="this.mainstore.validations.parcelSizeValid == true" ref="availableServices"/>
</template>

<script>
    import { useFormStore } from '@/stores/FormStore.ts';
    import { useMainStore } from '@/stores/MainStore.ts';
    import AvailableServices from '@/components/AvailableServices';

    export default {
        name: 'ParcelSize',

        components: {
            AvailableServices
        },

        setup() {
            const store = useFormStore()
            const mainstore = useMainStore()
            return {
                store,
                mainstore,
            }
        },

        data() {
            return {
                isSelected: '',
                products: this.mainstore.products
            }
        },

        computed: {
            parcelMaxWeight() {
                if(this.mainstore.selected.destination.webRiposteCode === 'IM') {
                return 30
                }
                else if(this.mainstore.selected.destination.webRiposteCode === 'UK' || this.mainstore.selected.destination.webRiposteCode === 'GK' || this.mainstore.selected.destination.webRiposteCode === 'JE') {
                    return 20
                }
                else {
                    return 2
                }
            }
        },

        methods: {
            showInfoLink(item) {
                return item.info !== null && !['IM', 'UK', 'JE', 'GY'].includes(this.mainstore.selected.destination.alpha2Code);
            },

            validate() {
                this.mainstore.validations.parcelSizeValid = true;
            },

            resetService() {
                this.mainstore.selected.service = null;
                this.mainstore.selected.serviceDetails = [];
                this.mainstore.validations.serviceValid = false;
            },

            onParcelSizeChange() {
                this.validate();
                this.resetService();
                //this.mainstore.selected.service = null;
                // Call getServices method on the child component directly
                this.$refs.availableServices.fetchServices();
            },
        },
        mounted() {
            // TODO: Remove with pricing engine
            // Exec wanted all references of Small Parcel to be Parcel. This method allows for that without any actual
            // data manipulation.
            this.products.forEach(product => {
                if (product.name == 'Small Parcel') {
                    product.displayName = "Parcel";
                } else {
                    product.displayName = product.name;
                }
            })
        }
    }
</script>
